import React from "react";
import "./TelegramMiniMultiConfirmationForm.css";
import TelegramText from "../Text/TelegramText";
import { useSelector } from "react-redux";

const TelegramMiniMultiConfirmationForm = (props) => {
  const userProfile = useSelector((state) => state.userProfile);
  const selectedTimeSlot = useSelector((state) => state.selectedTimeSlot);
  const checkoutComment = useSelector((state) => state.checkoutComment);

  return (
    <div
      {...props}
      className={"TelegramMiniMultiConfirmationForm " + props.className}
    >
      <TelegramText className={"telegramSubtitle"}>
        {props.fieldlabel_name}
      </TelegramText>
      <TelegramText className={"telegramText"}>
        {userProfile.contactname}
      </TelegramText>

      <TelegramText className={"telegramSubtitle"}>
        {props.fieldlabel_phone}
      </TelegramText>
      <TelegramText className={"telegramText"}>
        {userProfile.contactphone}
      </TelegramText>

      <TelegramText className={"telegramSubtitle"}>
        {props.fieldlabel_address}
      </TelegramText>
      <TelegramText className={"telegramText"}>
        {userProfile.postalcode}
      </TelegramText>

      <TelegramText className={"telegramSubtitle"}>
        {props.fieldlabel_timeslot}
      </TelegramText>
      <TelegramText className={"telegramText"}>
        {selectedTimeSlot.text}
      </TelegramText>
      <TelegramText className={"telegramSubtitle"}>
        {props.fieldlabel_comments}
      </TelegramText>
      <TelegramText className={"telegramText"}>{checkoutComment}</TelegramText>
      {/* <TelegramText className={"telegramHint"}>
        {props.fielddescription3}
      </TelegramText> */}
    </div>
  );
};

export default TelegramMiniMultiConfirmationForm;
