import React, { useCallback, useEffect, useState } from "react";
import "../../../App.css";

// import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../../components/kit/Text/TelegramText";
import { useTelegram } from "../../../hooks/useTelegram";
import { useNavigate } from "react-router-dom";
import {
  PATH_BRAND,
  PATH_CHECKOUT,
  PATH_ORDER_REVIEW,
  PATH_PRODUCT,
} from "../../../constants/Paths";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import ItemButton from "../../../components/kit/ItemButton/ItemButton";
import TelegramHeader from "../../../components/kit/Header/TelegramHeader";
import { useDispatch, useSelector } from "react-redux";
import TelegramDetailedButton from "../../../components/kit/DetailedButton/TelegramDetailedButton";
import Loader from "../../../components/kit/Loader/Loader";
import { replaceCart } from "../../../components/kit/Redux/Actions";

// import Cart from "../../../components/kit/Cart/Cart";

const { getProductByCategory } = require("../../../db/db");

const CategoryScreen = () => {
  const { webApp } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false); // State to track loading state

  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const selectedBrand = useSelector((state) => state.selectedBrand);
  const selectedProductType = useSelector((state) => state.selectedProductType);
  const categories = useSelector((state) => state.categories);
  const sessionToken = useSelector((state) => state.sessionToken);
  const agent = useSelector((state) => state.agent);
  const existingOrder = useSelector((state) => state.existingOrder);

  const onMainClick = useCallback(
    (selectedCategory) => {
      setIsLoading(true);

      const policyId = agent.policyid;

      getProductByCategory(
        sessionToken,
        policyId,
        selectedCategory,
        selectedBrand,
        selectedProductType
      )
        .then((data) => {
          dispatch({
            type: "UPDATE_SELECTED_CATEGORY",
            payload: selectedCategory,
          });

          dispatch({ type: "UPDATE_PRODUCT_LIST", payload: data });
          navigate(PATH_PRODUCT, {
            replace: true,
          });
        })
        .catch((error) => {
          setIsLoading(false);

          // Handle error if necessary
          alert("No categories were found. Please try again.");
        });
    },
    [navigate, dispatch]
  );

  const onBackClick = useCallback(() => {
    navigate(PATH_BRAND, {
      replace: true,
    });
  }, [navigate]);

  const onCartClick = useCallback(() => {
    setIsLoading(true);

    const sortedCartItems = [...cartItems].sort((a, b) => {
      // Sort by Type
      const typeResult = a.type.localeCompare(b.type);
      if (typeResult !== 0) return typeResult;

      // Sort by Brand
      const brandResult = a.brand.localeCompare(b.brand);
      if (brandResult !== 0) return brandResult;

      // Sort by Category
      const categoryResult = a.category.localeCompare(b.category);
      if (categoryResult !== 0) return categoryResult;

      // Sort by Title
      return a.title.localeCompare(b.title);
    });

    dispatch(replaceCart(sortedCartItems));

    if (existingOrder) {
      navigate(PATH_ORDER_REVIEW, { replace: true });
    } else {
      navigate(PATH_CHECKOUT, { replace: true });
    }
  }, [navigate, cartItems, dispatch]);

  useEffect(() => {
    webApp.ready();
    webApp.MainButton.onClick(onCartClick);
    webApp.BackButton.onClick(onBackClick);

    webApp.BackButton.show();

    if (cartItems.length > 0) {
      webApp.MainButton.text = "VIEW ORDER";
      webApp.MainButton.color = "#31b545";
      webApp.MainButton.show();
    } else {
      webApp.MainButton.hide();
    }
    return () => {
      webApp.MainButton.offClick(onCartClick);
      webApp.BackButton.offClick(onBackClick);
    };
  }, [webApp, cartItems, onMainClick, onBackClick, onCartClick]);

  return (
    categories && (
      <TelegramScreen>
        {!isLoading ? (
          <div>
            <TelegramHeader>
              <TelegramText className={"telegramTitle"}>
                {selectedBrand}
              </TelegramText>
            </TelegramHeader>
            {/* <TelegramDetailedButton
          // buttontitle={"Server Screen"}
          // buttondescription={"Interact with the bot server through REST API"}
          buttonlabel={"Go back :))"}
          onButtonClick={onBackClick}
        /> */}
            <div className="oneline__cards__container">
              {categories.map((item) => {
                return (
                  <ItemButton
                    item={item}
                    key={item.title}
                    handleButtonClick={onMainClick}
                    type="category"
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <Loader title="Rendering..." />
        )}
      </TelegramScreen>
    )
  );
};

export default CategoryScreen;
