export const buildOrderPayload = (
  userId,
  userName,
  orderName,
  orderPhone,
  orderAddress,
  postalCodeDetails,
  cart,
  timeSlot,
  discountAmount,
  deliveryCost,
  surchargeCost,
  baseCost,
  storeTotalCost,
  routeDetails,
  agentId,
  comments
) => {
  const payload = {
    Header: {
      UserId: userId,
      UserName: userName,
      AgentId: agentId,
      AddressDetails: postalCodeDetails,
      TimeSlot: timeSlot,
      RouteDetails: routeDetails,
    },
    Body: {
      Order: {
        OrderName: orderName,
        OrderPhone: orderPhone,
        OrderAddress: orderAddress,
        CartItems: cart,
        Comments: comments,
        Costs: {
          DiscountAmount: discountAmount,
          DeliveryCost: deliveryCost,
          SurchargeCost: surchargeCost,
          BaseCost: baseCost,
          StoreTotalCost: storeTotalCost,
        },
      },
    },
  };

  return payload;
};

export const buildOrderPayloadV2 = (
  userDetails,
  customerDetails,
  postalCodeDetails,
  featureFlags,
  cartItems,
  timeSlot,
  orderCosts,
  routeDetails,
  agentId
) => {
  const payload = {
    Header: {
      UserDetails: userDetails,
      AgentId: agentId,
      AddressDetails: postalCodeDetails,
      TimeSlot: timeSlot,
      RouteDetails: routeDetails,
      FeatureFlags: featureFlags,
    },
    Body: {
      Order: {
        CustomerDetails: customerDetails,
        CartItems: cartItems,
        Costs: orderCosts,
      },
    },
  };

  return payload;
};

export const buildAmendOrderPayload = (
  userDetails,
  customerDetails,
  postalCodeDetails,
  featureFlags,
  cartItems,
  existingOrder,
  existingCartItems,
  timeSlot,
  orderCosts,
  routeDetails,
  agentId
) => {
  const payload = {
    Header: {
      UserDetails: userDetails,
      AgentId: agentId,
      AddressDetails: postalCodeDetails,
      TimeSlot: timeSlot,
      RouteDetails: routeDetails,
      FeatureFlags: featureFlags,
    },
    Body: {
      Order: {
        CustomerDetails: customerDetails,
        CartItems: cartItems,
        ExistingCartItems: existingCartItems,
        Costs: orderCosts,
      },
      ExistingOrder: existingOrder,
    },
  };

  return payload;
};

export const buildShipmentOrderPayload = (
  userId,
  issueId,
  routeId,
  routeDetailId,
  timeSlotDate,
  timeSlotPeriod
) => {
  const payload = {
    UserId: userId,
    IssueId: issueId,
    RouteId: routeId,
    RouteDetailId: routeDetailId,
    TimeSlotDate: timeSlotDate,
    TimeSlotPeriod: timeSlotPeriod,
  };

  return payload;
};

export const buildBroadcastMessagePayload = (
  recipientType,
  message,
  agentId,
  userId
) => {
  const payload = {
    BroadcastMessage: message,
    RecipientType: recipientType,
    AgentId: agentId,
    UserId: userId,
  };

  return payload;
};

export const buildUserPayload = (userId, userName, phone, address) => {
  const payload = {
    UserId: userId,
    UserName: userName,
    Phone: phone,
    Address: address,
  };

  return payload;
};

export const buildInventoryPayload = (cartItems, existingOrderCartItems) => {
  const payload = {
    ExistingItems: existingOrderCartItems,
    Items: cartItems.map((item) => ({
      id: item.id,
      title: item.title,
      price: item.price,
      brand: item.brand,
      category: item.category,
      quantity: item.quantity,
      availableQty: item.availableQty,
    })),
  };

  return payload;
};
