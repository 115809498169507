import React from "react";
import "./PromotionCard.css";

function PromotionCard({ promotion, hidePrice }) {
  const { chargedescription, chargeamount } = promotion;

  return (
    <div className="promotion__card">
      <div className="promotion__image__container">🐣</div>
      <div className="promotion__card__title">{chargedescription}</div>

      {hidePrice ? (
        ""
      ) : (
        <div className="promotion__card__total">
          <span>{"$" + Math.abs(parseFloat(chargeamount)).toFixed(2)}</span>
        </div>
      )}
    </div>
  );
}

export default PromotionCard;
