import { API_URL } from "./Variables";
import {
  buildBroadcastMessagePayload,
  buildInventoryPayload,
  buildOrderPayload,
  buildShipmentOrderPayload,
  buildUserPayload,
} from "./PayloadBuilder";

const {
  createNewOrder,
  broadcastMessage,
  updateUserToServer,
  getDeliveryTimeSlots,
  createNewDeliveryOrder,
  validateAvailableInventory,
  getChargeByCode,
  getChargesByCodeNOperator,
  getCharges,
  prepareOrder,
  editOrder,
} = require("../../db/db");

/**
 * Uses simple fetch() to send message to the bot server using REST API
 *
 * @param message the message to be sent
 * @param queryId get from webApp.initDataUnsafe?.query_id
 */
export const sendMessageToServer = async (token, message, userId) => {
  const body = {
    chat_id: userId,
    text: message,
    parse_mode: "MarkdownV2",
  };
  const messagesEndpoint = `${API_URL}/sendMessage`;
  const response = await fetch(messagesEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  return response;
};

/**
 * Uses simple fetch() to send message to the bot server using REST API
 *
 * @param message the message to be sent
 * @param queryId get from webApp.initDataUnsafe?.query_id
 */
export const createNewOrderToServer = async (token, orderSummary) => {
  try {
    const data = await createNewOrder(token, orderSummary);

    if (!data || data.length === 0) {
      // statusText === 'Created'
      //
      throw new Error(
        "There has been some changes in the system.\n\nPlease try again."
      );
    }

    if (!data.order) {
      throw new Error(
        "There has been some changes in the system.\n\nPlease try again."
      );
    }

    if (data || data.length > 0) {
      return data;
    } else {
      return;
    }
    // Handle the response as needed
  } catch (error) {
    console.error(error);
    // Handle the error gracefully
  }
};
export const editOrderToServer = async (token, orderSummary) => {
  try {
    const data = await editOrder(token, orderSummary);

    if (!data || data.length === 0) {
      // statusText === 'Created'
      //
      throw new Error(
        "There has been some changes in the system.\n\nPlease try again."
      );
    }

    if (!data.order) {
      throw new Error(
        "There has been some changes in the system.\n\nPlease try again."
      );
    }

    if (data || data.length > 0) {
      return data;
    } else {
      return;
    }
    // Handle the response as needed
  } catch (error) {
    console.error(error);
    // Handle the error gracefully
  }
};

/**
 * Uses simple fetch() to send message to the bot server using REST API
 *
 * @param message the message to be sent
 * @param queryId get from webApp.initDataUnsafe?.query_id
 */
export const createNewDeliveryToServer = async (
  token,
  userId,
  issueId,
  routeId,
  routeDetailId,
  timeSlotDate,
  timeSlotPeriod
) => {
  const payload = buildShipmentOrderPayload(
    userId,
    issueId,
    routeId,
    routeDetailId,
    timeSlotDate,
    timeSlotPeriod
  );

  try {
    const data = await createNewDeliveryOrder(token, payload);

    if (!data || data.length === 0) {
      // statusText === 'Created'
      //
      throw new Error("Failed to create new delivery.");
    }

    if (!data.order) {
      throw new Error("Failed to create new delivery.");
    }

    return data;
    // Handle the response as needed
  } catch (error) {
    console.error(error);
    // Handle the error gracefully
  }
};

/**
 * Uses simple fetch() to send message to the bot server using REST API
 *
 * @param message the message to be sent
 * @param queryId get from webApp.initDataUnsafe?.query_id
 */
export const broadcastMessageToServer = async (
  token,
  recipientType,
  message,
  agentId,
  userId
) => {
  const payload = buildBroadcastMessagePayload(
    recipientType,
    message,
    agentId,
    userId
  );
  try {
    const data = await broadcastMessage(token, payload);

    if (!data || data.length === 0) {
      // statusText === 'Created'
      //
      throw new Error("Failed to broadcast message.");
    }

    return data;
    // Handle the response as needed
  } catch (error) {
    console.error(error);
    throw new Error(error);
    // Handle the error gracefully
  }
};

/**
 * Uses simple fetch() to send message to the bot server using REST API
 *
 * @param message the message to be sent
 * @param queryId get from webApp.initDataUnsafe?.query_id
 */
export const createNewUser = async (
  token,
  checkIfExists,
  userId,
  name,
  phone,
  address
) => {
  try {
    const payload = buildUserPayload(userId, name, phone, address);
    // if (checkIfExists) {
    //   const userExists = await getUserByUserId(userId);

    //   if (userExists && userExists.length > 0) {

    // Let's update the new user details
    const data = await updateUserToServer(token, payload);

    //     return data;
    //   }
    // }

    // const payload = buildUserPayload(userId, name, phone, address);

    // const data = await createNewUserToServer(token, payload);

    return data;
    // Handle the response as needed
  } catch (error) {
    throw new Error(error);
    // Handle the error gracefully
  }
};

/**
 * Uses simple fetch() to send message to the bot server using REST API
 *
 * @param message the message to be sent
 * @param queryId get from webApp.initDataUnsafe?.query_id
 */
export const getDeliveryTimeSlotsFromServer = async (token) => {
  try {
    const response = await getDeliveryTimeSlots(token);

    return response;
    // Handle the response as needed
  } catch (error) {
    console.error(error);
    throw new Error(error);
    // Handle the error gracefully
  }
};

/**
 * Uses simple fetch() to send message to the bot server using REST API
 *
 * @param message the message to be sent
 * @param queryId get from webApp.initDataUnsafe?.query_id
 */
export const validateAvailableInventoryToServer = async (
  token,
  cartItems,
  existingOrderCartItems
) => {
  try {
    const payload = buildInventoryPayload(cartItems, existingOrderCartItems);

    const data = await validateAvailableInventory(token, payload);

    return data;
    // Handle the response as needed
  } catch (error) {
    console.error(error);
    throw new Error(error);
    // Handle the error gracefully
  }
};

export const prepareOrderToServer = async (
  token,
  cartItems,
  charges,
  routeCode,
  orderProfile
) => {
  try {
    const payload = {
      Body: {
        CartItems: cartItems,
        ChargeList: charges,
        OrderRoute: routeCode,
        OrderProfile: orderProfile,
      },
    };
    const data = await prepareOrder(token, payload);

    return data;
    // Handle the response as needed
  } catch (error) {
    console.error(error);
    throw new Error(error);
    // Handle the error gracefully
  }
};
