// AlertContext.js
import React, { createContext, useContext, useState } from "react";
import Alert from "../kit/Alert/Alert";

export const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alertProps, setAlertProps] = useState({
    isOpen: false,
    title: "",
    description: "",
  });

  const triggerAlert = (title, description) => {
    setAlertProps({ isOpen: true, title, description });
  };

  const closeAlert = () => {
    setAlertProps({ isOpen: false, title: "", description: "" });
  };

  return (
    <AlertContext.Provider value={{ triggerAlert, closeAlert }}>
      {children}
      {alertProps.isOpen && (
        <Alert
          title={alertProps.title}
          description={alertProps.description}
          setTrigger={closeAlert}
        />
      )}
    </AlertContext.Provider>
  );
};
