import React, { useCallback, useEffect } from "react";
import { useTelegram } from "../../../hooks/useTelegram";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../../components/kit/Header/TelegramHeader";
import TelegramText from "../../../components/kit/Text/TelegramText";
import { useNavigate } from "react-router-dom";

import { PATH_CHECKOUT } from "../../../constants/Paths";
import ConfirmUserDetailsFormMessage from "../../../components/app/server/ConfirmUserDetailsFormMessage";

const PaymentInfoScreen = ({ onSubmit }) => {
  const { webApp } = useTelegram();
  const navigate = useNavigate();

  const onBackClick = useCallback(() => {
    navigate(PATH_CHECKOUT, {
      replace: true,
    });
  }, [navigate]);

  useEffect(() => {
    webApp.ready();
    webApp.BackButton.show();
    webApp.BackButton.onClick(onBackClick);

    return () => {
      webApp.BackButton.offClick(onBackClick);
    };
  }, [webApp, onBackClick]);

  return (
    <TelegramScreen className="full-height">
      <TelegramHeader>
        <TelegramText className={"telegramTitle"}>TROLLEY</TelegramText>
      </TelegramHeader>

      <ConfirmUserDetailsFormMessage />
    </TelegramScreen>
  );
};

export default PaymentInfoScreen;
