import React, { useCallback, useEffect, useState } from "react";
import { useTelegram } from "../../../hooks/useTelegram";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../../components/kit/Header/TelegramHeader";
import TelegramText from "../../../components/kit/Text/TelegramText";
import { useNavigate } from "react-router-dom";

import CheckoutCard from "../../../components/kit/CheckoutCard/CheckoutCard";
import {
  PATH_BRAND,
  PATH_CATEGORY,
  PATH_PRODUCT,
  PATH_PRODUCT_TYPE,
} from "../../../constants/Paths";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  removeFromCart,
} from "../../../components/kit/Redux/Actions";
import {
  areCartItemsSame,
  reviewOrder,
} from "../../../components/utils/review-order";
import { useAlert } from "../../../components/providers/AlertProvider";

const OrderReviewScreen = () => {
  const { webApp, user } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cartItems = useSelector((state) => state.cartItems.cartItems);

  const checkoutComment = useSelector((state) => state.checkoutComment);
  const totalPrice = cartItems.reduce((a, c) => a + c.price * c.quantity, 0);
  const totalItems = cartItems.reduce((a, c) => a + c.quantity, 0);

  const sessionToken = useSelector((state) => state.sessionToken);
  const surchargeChargeList = useSelector((state) => state.surchargeChargeList);
  const deliveryChargeList = useSelector((state) => state.deliveryChargeList);
  const exclusivePromotionChargeList = useSelector(
    (state) => state.exclusivePromotionChargeList
  );
  const existingOrder = useSelector((state) => state.existingOrder);
  const existingOrderCartItems = useSelector(
    (state) => state.existingOrderCartItems
  );
  const selectedBrand = useSelector((state) => state.selectedBrand);
  const selectedProductType = useSelector((state) => state.selectedProductType);
  const selectedCategory = useSelector((state) => state.selectedCategory);

  const { triggerAlert } = useAlert();

  // This id will be used by our backend to send messages to the chat with the user
  const queryId = webApp.initDataUnsafe?.query_id;

  const onMainClick = useCallback(async () => {
    try {
      if (areCartItemsSame(cartItems, existingOrderCartItems)) {
        triggerAlert("Cart", "No changes made to the cart.");
        // alert(`No changes made to the cart.`);
        return;
      }
      const utils = { webApp, queryId, sessionToken, dispatch, navigate };
      const userId = user?.id;
      const {
        customerName,
        customerContact,
        customerAddress,
        timeSlot,
        agentId,
      } = existingOrder;
      const userDetails = {
        userId,
        agentId,
        customerName,
        customerContact,
        customerAddress,
        timeSlot,
        surchargeChargeList,
        deliveryChargeList,
        exclusivePromotionChargeList,
      };
      await reviewOrder(utils, userDetails, cartItems, existingOrderCartItems);
    } catch (error) {
      alert(error);
      throw error;
    }
  }, [
    webApp,
    queryId,
    sessionToken,
    dispatch,
    navigate,
    user,
    existingOrder,
    existingOrderCartItems,
    surchargeChargeList,
    deliveryChargeList,
    exclusivePromotionChargeList,
    cartItems,
    triggerAlert,
  ]);

  const onBackClick = useCallback(() => {
    if (selectedCategory) {
      navigate(PATH_PRODUCT, {
        replace: true,
      });
      return;
    }

    if (selectedBrand) {
      navigate(PATH_CATEGORY, {
        replace: true,
      });
      return;
    }

    if (selectedProductType) {
      navigate(PATH_BRAND, {
        replace: true,
      });
      return;
    }

    navigate(PATH_PRODUCT_TYPE, {
      replace: true,
    });
  }, [navigate, selectedCategory, selectedBrand, selectedProductType]);

  useEffect(() => {
    webApp.ready();
    webApp.MainButton.onClick(onMainClick);
    webApp.BackButton.onClick(onBackClick);

    webApp.MainButton.text = `CHECKOUT`;
    webApp.MainButton.color = "#31b545";

    webApp.BackButton.show();

    if (cartItems.length > 0) {
      webApp.MainButton.show();
    } else {
      webApp.MainButton.hide();
    }

    return () => {
      webApp.MainButton.offClick(onMainClick);
      webApp.BackButton.offClick(onBackClick);
    };
  }, [
    webApp,
    cartItems,
    onMainClick,
    onBackClick,
    totalPrice,
    checkoutComment,
  ]);

  const onAdd = (item, newAddQty) => {
    dispatch(addToCart(item, newAddQty));
  };

  const onRemove = (item, newSubQty) => {
    dispatch(removeFromCart(item, newSubQty));
  };

  return (
    <TelegramScreen>
      <div>
        <TelegramHeader>
          <TelegramText className={"telegramTitle"}>
            REVIEW TROLLEY
          </TelegramText>
        </TelegramHeader>

        {cartItems.length > 0 ? (
          <div className="checkout__container">
            <div className="checkout__cards__container">
              {cartItems.map((item) => {
                return (
                  <CheckoutCard
                    food={item}
                    quantity={(item.orderedqty || 0) + (item.quantity || 0)}
                    availableQty={item.availableQty || 0}
                    key={item.id}
                    onAdd={onAdd}
                    onRemove={onRemove}
                  />
                );
              })}
            </div>

            <div className="checkout__total__items__container">
              <TelegramText>Total Items:</TelegramText>
              <TelegramText>
                <span>{totalItems}</span>
              </TelegramText>
            </div>
            <div className="checkout__cost__container">
              <TelegramText>Total Cost:</TelegramText>
              <TelegramText>
                <span>${totalPrice.toFixed(2)}</span>
              </TelegramText>
            </div>
          </div>
        ) : (
          <TelegramText className={"centered-text"}>Empty Cart</TelegramText>
        )}
      </div>
    </TelegramScreen>
  );
};

export default OrderReviewScreen;
