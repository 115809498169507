import React from "react";
import "./CheckoutButton.css";

function Button({ type, title, disable, onClick, className }) {
  return (
    <button
      className={`checkoutbtn ${
        (type === "add" && "add") ||
        (type === "subtract" && "subtract") ||
        (type === "checkout" && "checkout")
      } ${className}`}
      disabled={disable}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export default Button;
