import React, { useCallback, useEffect, useState } from "react";
import TelegramInput from "../Input/TelegramInput";
import "./TelegramMiniMultiInputForm.css";
import TelegramText from "../Text/TelegramText";
import { useTelegram } from "../../../hooks/useTelegram";
import TelegramOptionsForm from "../OptionsForm/TelegramOptionsForm";
import { useDispatch, useSelector } from "react-redux";
import TelegramMiniForm from "../MiniForm/TelegramMiniForm";
import { updateCheckoutComment } from "../Redux/Actions";

// const slots = ["2PM - 7PM", "8PM - 12AM"];

// 1/Jun/2024 VE  - Updated timeslot for 1 only
// WIP: To set to 2 when we can schedule the broadcasting of messages
// Function to calculate available time slots
const getAvailableTimeSlots = (deliveryTimeSlots) => {
  const availableSlots = [];
  const daysToShow = 2; // Number of days to show time slots
  const currentDateTime = new Date();
  const currentTime =
    currentDateTime.getHours() + currentDateTime.getMinutes() / 60;

  // Convert currentTime to minutes
  const currentTimeInMinutes =
    currentDateTime.getHours() * 60 + currentDateTime.getMinutes();

  let remainingSlots = 2; // Maximum number of slots to include

  for (let i = 0; i < daysToShow; i++) {
    const currentDate = new Date();
    currentDate.setDate(currentDateTime.getDate() + i);
    const currentDay = currentDate.getDate();
    // const currentMonth = currentDate.getMonth() + 1;
    const currentMonth = currentDate.toLocaleString("default", {
      month: "short",
    });
    const currentYear = currentDate.getFullYear();

    for (const slot of deliveryTimeSlots) {
      const timePeriod = slot.timeperiod;
      const timeRange = slot.timeslot;
      const deliveryFromHour = parseInt(slot.deliveryfromtime);
      const deliveryToHour = parseInt(slot.deliverytotime);
      const cutoffHour = parseInt(slot.cutofftimehour);
      const cutoffMin = parseInt(slot.cutofftimemin);

      // Calculate the cutoff time in minutes
      const cutoffTimeInMinutes = cutoffHour * 60 + cutoffMin;

      // Check if the cutoff time is after the current time
      if (
        cutoffTimeInMinutes > currentTimeInMinutes ||
        i > 0 ||
        currentDay > currentDateTime.getDate()
      ) {
        const timeslotDate = new Date(
          currentYear,
          currentDate.getMonth(),
          currentDay,
          deliveryFromHour,
          0,
          0
        );

        // Check if the timeslot is in the future
        if (
          currentDate > currentDateTime ||
          (currentDate.getDate() === currentDateTime.getDate() &&
            deliveryFromHour > currentDateTime.getHours())
        ) {
          const timeSlot = {
            id: slot.id,
            text: `${currentDay} ${currentMonth} ${currentYear} - ${slot.timeslot}`,
            datetime: timeslotDate,
            deliveryfromhour: deliveryFromHour,
            deliverytohour: deliveryToHour,
            timeperiod: timePeriod,
            timerange: timeRange,
          };
          availableSlots.push(timeSlot);
          remainingSlots--;

          if (remainingSlots === 0) {
            return availableSlots.slice(0, 2); // Limit to 2 slots per purchase
          }
        }
      }
    }
  }

  return availableSlots.slice(0, 2); // Limit to 2 slots per purchase
};

const TelegramMiniMultiInputForm = (props) => {
  const { webApp } = useTelegram();
  const dispatch = useDispatch();

  // const selectedTimeSlot = useSelector((state) => state.selectedTimeSlot);
  const userProfile = useSelector((state) => state.userProfile);
  const availableSlots = useSelector((state) => state.deliveryTimeSlots);

  const [selectedSlot, setSelectedSlot] = useState(0);
  const [input1, setInput1] = useState(userProfile?.contactname || "");
  const [input2, setInput2] = useState(userProfile?.contactphone || "");
  const [input3, setInput3] = useState(userProfile?.postalcode || "");
  const checkoutComment = useSelector((state) => state.checkoutComment);
  const [commentValue, setCommentValue] = useState(checkoutComment);

  // Calculate available time slots based on the current time
  // const availableSlots = getAvailableTimeSlots(deliveryTimeSlots);

  // setTimeSlots(availableSlots);

  const onChangeInput1 = (e) => {
    setInput1(e.target.value);
  };

  const onChangeInput2 = (e) => {
    setInput2(e.target.value);
  };

  const onChangeInput3 = (e) => {
    setInput3(e.target.value);
  };

  // Event handler for selecting a time slot
  const handleTimeSlotChange = (event) => {
    setSelectedSlot(event.target.value);
  };

  const onButtonClick = useCallback(() => {
    // Perform validation here
    try {
      const officialPostalCodePrefixes = [
        // Central
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",

        // East
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "81",

        // West
        "11",
        "12",
        "13",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",

        // North
        "53",
        "54",
        "55",
        "56",
        "57",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "82",

        // Self-Collection
        "99",
      ];
      const selectedTimeSlot = availableSlots[selectedSlot];

      if (!input1) {
        alert("Please input a valid name.");
        return;
      }

      if (!input2) {
        alert("Please input a valid phone no.");
        return;
      }

      if (!input3) {
        alert("Please input a valid address.");
        return;
      }

      if (!selectedTimeSlot) {
        alert("Please input a valid timeslot.");
        return;
      }

      if (!/^[A-Za-z ]+$/.test(input1) || input1.trim() === "") {
        // Display an error message or prevent the user from proceeding
        alert("Please input a valid name."); // Display an error message or prevent the user from proceeding
        return;
      }

      // 28/May/2024 VE   - Change the validation to either start with + or number, and can only contain numbers
      if (!/^(\+|\d)(\d*)?$/.test(input2) || input2.trim() === "") {
        // Display an error message or prevent the user from proceeding
        alert("Phone No. can only start with '+' or a digit."); // Display an error message or prevent the user from proceeding
        return;
      }

      if (
        !/^\d{6}$/.test(input3) ||
        input3.trim() === "" ||
        !officialPostalCodePrefixes.some((prefix) => input3.startsWith(prefix))
      ) {
        // Display an error message or prevent the user from proceeding
        alert("Please input a valid postal code."); // Display an error message or prevent the user from proceeding
        return;
      }

      if (!selectedTimeSlot) {
        alert("Please select a timeslot."); // Display an error message or prevent the user from proceeding
        return;
      }

      if (commentValue) {
        const validCommentValue =
          // /^[a-zA-Z0-9\s!@#$%^&*()_+\-=[\]{};:"',./<>?]*$/.test(commentValue);
          /^[a-zA-Z0-9+#-\s]*$/.test(commentValue);

        if (validCommentValue) {
          dispatch(updateCheckoutComment(commentValue));
        } else {
          // Handle invalid commentValue
          // For example, show an error message or prevent navigation
          alert("[Comments] Invalid comments.");
          return;
        }
      }

      props.onSubmit({
        name: input1,
        phone: input2,
        address: input3,
        timeSlot: selectedTimeSlot,
      });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }, [
    input1,
    input2,
    input3,
    selectedSlot,
    availableSlots,
    commentValue,
    props,
    dispatch,
  ]);

  useEffect(() => {
    // const defaultSlot = availableSlots[0].datetime.toString();
    // setSelectedSlot(defaultSlot);
    webApp.ready();
    webApp.MainButton.onClick(onButtonClick);

    webApp.MainButton.text = `NEXT`;
    // webApp.MainButton.color = "#1c54bd";
    webApp.MainButton.color = "#31b545";
    webApp.MainButton.show();
    // webApp.MainButton.showProgress(true);

    return () => {
      webApp.MainButton.offClick(onButtonClick);
    };
  }, [webApp, onButtonClick]);

  return (
    <div {...props} className={"TelegramMiniMultiInputForm " + props.className}>
      <TelegramText className={"telegramSubtitle"}>
        {props.fieldlabel1}
      </TelegramText>
      {/* <TelegramText className={"telegramHint"}>
        {props.fielddescription1}
      </TelegramText> */}
      <TelegramInput
        type="text"
        placeholder={props.fieldhint1}
        value={input1}
        onChange={onChangeInput1}
      />

      <TelegramText className={"telegramSubtitle"}>
        {props.fieldlabel2}
      </TelegramText>
      {/* <TelegramText className={"telegramHint"}>
        {props.fielddescription2}
      </TelegramText> */}
      <TelegramInput
        type="text"
        placeholder={props.fieldhint2}
        value={input2}
        onChange={onChangeInput2}
      />

      {/* <TelegramPhoneInput
        type="text"
        placeholder={props.fieldhint2}
        value={input2}
        onChange={onChangeInput2}
      /> */}
      <TelegramText className={"telegramSubtitle"}>
        {props.fieldlabel3}
      </TelegramText>
      {/* <TelegramText className={"telegramHint"}>
        {props.fielddescription3}
      </TelegramText> */}
      <TelegramInput
        type="text"
        placeholder={props.fieldhint3}
        value={input3}
        onChange={onChangeInput3}
      />

      <TelegramOptionsForm
        // formlabel={'MainButton.showProgress(leaveActive)'}
        // formdescription={
        //     'A method to show a loading indicator on the button.' +
        //     'It is recommended to display loading progress if the action tied to the button may take a long time. By default, the button is disabled while the action is in progress. If the parameter leaveActive=true is passed, the button remains enabled.'
        // }
        optionsmultiple={false}
        optionslabel={props.fieldlabel4}
        options={availableSlots}
        // buttonlabel={"Execute"}
        onChange={handleTimeSlotChange}
      />

      <div class="checkout__comment__container">
        <TelegramMiniForm
          // fieldlabel={"Name (名字)"}
          fielddescription={
            "Only special characters like # and + are allowed.\nAny special requests, details, wishes etc. "
          }
          // fieldhint={`Add comment 话太多...`}
          fieldhint={`Add comment...`}
          fieldvalue={commentValue}
          onChange={(e) => {
            setCommentValue(e.target.value);
          }}
          // onSubmit={onMainClick}
        />
      </div>

      {/* <TelegramButton
        className={`align-bottom ${props.buttondisabled ? "disabled" : ""}`}
        onClick={onButtonClick}
      >
        PAY
      </TelegramButton> */}
      {/* {user ? (
        <webApp.MainButton text="PAY" color="31b545" onClick={onButtonClick} />
      ) : (
        <></>
      )} */}
    </div>
  );
};

export default TelegramMiniMultiInputForm;
