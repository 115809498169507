import React, { useCallback, useEffect, useState } from "react";
import "../../../App.css";

// import TelegramHeader from "../../../components/kit/Header/TelegramHeader";
import TelegramText from "../../../components/kit/Text/TelegramText";
import { useTelegram } from "../../../hooks/useTelegram";
import { useNavigate } from "react-router-dom";
import {
  PATH_BRAND,
  PATH_CHECKOUT,
  PATH_HOME,
  PATH_ORDER_REVIEW,
} from "../../../constants/Paths";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import ItemButton from "../../../components/kit/ItemButton/ItemButton";
import TelegramHeader from "../../../components/kit/Header/TelegramHeader";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/kit/Loader/Loader";
import { replaceCart } from "../../../components/kit/Redux/Actions";
import { useAlert } from "../../../components/providers/AlertProvider";
// import Cart from "../../../components/kit/Cart/Cart";

const { getBrandByType } = require("../../../db/db");

const ProductTypeScreen = () => {
  const { webApp } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { triggerAlert } = useAlert();

  const [isLoading, setIsLoading] = useState(false); // State to track loading state

  const productTypes = useSelector((state) => state.productTypeList);
  const sessionToken = useSelector((state) => state.sessionToken);
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const existingOrder = useSelector((state) => state.existingOrder);

  const onMainClick = useCallback(
    (selectedProductType) => {
      setIsLoading(true);

      getBrandByType(sessionToken, selectedProductType)
        .then((data) => {
          dispatch({
            type: "UPDATE_SELECTED_PRODUCT_TYPE",
            payload: selectedProductType,
          });
          dispatch({ type: "UPDATE_BRAND_LIST", payload: data });

          navigate(PATH_BRAND, {
            replace: true,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          // Handle error if necessary
        });
    },
    [navigate, dispatch, sessionToken]
  );

  const onCartClick = useCallback(() => {
    setIsLoading(true);

    const sortedCartItems = [...cartItems].sort((a, b) => {
      // Sort by Type
      const typeResult = a.type.localeCompare(b.type);
      if (typeResult !== 0) return typeResult;

      // Sort by Brand
      const brandResult = a.brand.localeCompare(b.brand);
      if (brandResult !== 0) return brandResult;

      // Sort by Category
      const categoryResult = a.category.localeCompare(b.category);
      if (categoryResult !== 0) return categoryResult;

      // Sort by Title
      return a.title.localeCompare(b.title);
    });

    if (existingOrder) {
      navigate(PATH_ORDER_REVIEW, { replace: true });
    } else {
      navigate(PATH_CHECKOUT, { replace: true });
    }
  }, [navigate, cartItems, dispatch]);

  useEffect(() => {
    if (productTypes === null) {
      navigate(PATH_HOME, { replace: true }); // Adjust the path as per your routing setup
      triggerAlert("Page Reload", "Please try again.");
    }
    webApp.ready();
    webApp.MainButton.onClick(onCartClick);

    webApp.BackButton.hide();

    if (cartItems.length > 0) {
      webApp.MainButton.text = "VIEW ORDER";
      webApp.MainButton.color = "#31b545";
      webApp.MainButton.show();
    } else {
      webApp.MainButton.hide();
    }

    return () => {
      webApp.MainButton.offClick(onCartClick);
    };
  }, [
    webApp,
    onMainClick,
    onCartClick,
    cartItems,
    navigate,
    productTypes,
    triggerAlert,
  ]);

  return (
    <TelegramScreen>
      {!isLoading ? (
        <div>
          {productTypes?.length > 0 ? (
            <>
              <TelegramHeader>
                <TelegramText className={"telegramTitle"}>TYPE</TelegramText>
              </TelegramHeader>
              <div className="oneline__cards__container">
                {productTypes.map((item) => {
                  return (
                    <ItemButton
                      item={item}
                      key={item.title}
                      handleButtonClick={onMainClick}
                      type="type"
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <TelegramHeader>
                <TelegramText className={"telegramTitle"}>
                  No inventory at the moment
                </TelegramText>
              </TelegramHeader>
            </>
          )}
        </div>
      ) : (
        <Loader title="Retrieving.." />
      )}
    </TelegramScreen>
  );
};

export default ProductTypeScreen;
