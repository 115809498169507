import "./App.css";
import { Route, Routes } from "react-router-dom";

import {
  PATH_CHECKOUT,
  PATH_PRODUCT,
  PATH_CATEGORY,
  PATH_BRAND,
  PATH_PAYMENT,
  PATH_THANKYOU,
  PATH_PAYMENT_INFO,
  PATH_PRODUCT_TYPE,
  PATH_ORDER_MANAGEMENT,
  PATH_ORDER_AMENDMENT,
  PATH_ORDER_REVIEW,
} from "./constants/Paths";
import {
  Main,
  CheckoutScreen,
  PaymentScreen,
  ProductScreen,
  CategoryScreen,
  BrandScreen,
  ThankYouScreen,
  PaymentInfoScreen,
  ProductTypeScreen,
  OrderManagementScreen,
  OrderAmendmentScreen,
  OrderReviewScreen,
} from "./screens/Store";
import { useTelegram } from "./hooks/useTelegram";
import TelegramText from "./components/kit/Text/TelegramText";
import { AlertProvider } from "./components/providers/AlertProvider";
import { ModalProvider } from "./components/providers/ModalProvider";

function App() {
  const { webApp } = useTelegram();
  const queryId = webApp.initDataUnsafe?.query_id;

  return (
    <>
      {queryId ? (
        <div className="App">
          <ModalProvider>
            <AlertProvider>
              <Routes>
                <Route index element={<Main />} />
                <Route
                  path={PATH_PRODUCT_TYPE}
                  element={<ProductTypeScreen />}
                />
                <Route path={PATH_CATEGORY} element={<CategoryScreen />} />
                <Route path={PATH_BRAND} element={<BrandScreen />} />
                <Route path={PATH_PRODUCT} element={<ProductScreen />} />
                <Route path={PATH_CHECKOUT} element={<CheckoutScreen />} />
                <Route path={PATH_PAYMENT} element={<PaymentScreen />} />
                <Route
                  path={PATH_PAYMENT_INFO}
                  element={<PaymentInfoScreen />}
                />
                <Route path={PATH_THANKYOU} element={<ThankYouScreen />} />
                <Route
                  path={PATH_ORDER_MANAGEMENT}
                  element={<OrderManagementScreen />}
                />
                <Route
                  path={PATH_ORDER_AMENDMENT}
                  element={<OrderAmendmentScreen />}
                />
                <Route
                  path={PATH_ORDER_REVIEW}
                  element={<OrderReviewScreen />}
                />
              </Routes>
            </AlertProvider>
          </ModalProvider>
        </div>
      ) : (
        <TelegramText className={`centered-text`}>
          Unauthorized 403
        </TelegramText>
      )}
    </>
  );
}

export default App;
