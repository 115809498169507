import React from "react";
import "./Alert.css";
import { FaRocket } from "react-icons/fa6";
import Button from "../Button/Button";
import TelegramButton from "../Button/TelegramButton";

function Alert(props) {
  // If trigger is true, render the Alert component
  // if (!props.trigger) return null;

  return (
    <div onClick={() => props.setTrigger(false)} className="alert__container">
      <div onClick={(e) => e.stopPropagation()} className="alert">
        <div className="alert__icon">
          <span className="">
            <FaRocket />
          </span>
        </div>
        <div className="alert__content">
          <span className="alert__title">{props.title}</span>
          <span className="alert__description">{props.description}</span>
        </div>
        <TelegramButton
          className="alert__close__btn"
          onClick={() => props.setTrigger(false)}
        >
          X
        </TelegramButton>
      </div>
    </div>
  );
}

export default Alert;
