// actions.js
// Updated addToCart action creator to include newAddQty
export const addToCart = (item, newAddQty) => ({
  type: "ADD_TO_CART",
  payload: { item, quantity: newAddQty },
});

// Updated removeFromCart action creator to include newSubQty
export const removeFromCart = (item, newSubQty) => ({
  type: "REMOVE_FROM_CART",
  payload: { item, quantity: newSubQty },
});

export const removeFullItemFromCart = (item) => ({
  type: "REMOVE_FULL_ITEM_FROM_CART",
  payload: item,
});

export const replaceCart = (cart) => ({
  type: "REPLACE_CART",
  payload: cart,
});

export const updateSelectedBrand = (brand) => ({
  type: "UPDATE_SELECTED_BRAND",
  payload: brand,
});

export const updateSelectedCategory = (category) => ({
  type: "UPDATE_SELECTED_CATEGORY",
  payload: category,
});

export const updateBrandList = (brandList) => ({
  type: "UPDATE_BRAND_LIST",
  payload: brandList,
});

export const updateCategoryList = (categoryList) => ({
  type: "UPDATE_PRODUCT_LIST",
  payload: categoryList,
});

export const updateUserProfile = (user) => ({
  type: "UPDATE_USER_PROFILE",
  payload: user,
});

export const updateUserProfileDetails = (userDetails) => ({
  type: "UPDATE_USER_PROFILE",
  payload: userDetails,
});

export const updateSessionToken = (sessionToken) => ({
  type: "UPDATE_SESSION_TOKEN",
  payload: sessionToken,
});

export const updateThankYouMessage = (message) => ({
  type: "UPDATE_THANKYOU_MESSAGE",
  payload: message,
});

export const updateAgent = (agent) => ({
  type: "UPDATE_AGENT",
  payload: agent,
});

export const updateDeliveryTimeSlot = (deliveryTimeSlots) => ({
  type: "UPDATE_DELIVERY_TIME_SLOT",
  payload: deliveryTimeSlots,
});
export const updateCurrentTimeSlotOrders = (currentTimeSlotOrders) => ({
  type: "UPDATE_CURRENT_TIME_SLOT_ORDERS",
  payload: currentTimeSlotOrders,
});

export const updateTotalCost = (totalCost) => ({
  type: "UPDATE_TOTAL_COST",
  payload: totalCost,
});

export const updateCheckoutComment = (comment) => ({
  type: "UPDATE_CHECKOUT_COMMENT",
  payload: comment,
});

export const updateSelectedTimeSlot = (timeSlot) => ({
  type: "UPDATE_SELECTED_TIME_SLOT",
  payload: timeSlot,
});

export const updateSurchargeChargeList = (chargeList) => ({
  type: "UPDATE_SURCHARGE_CHARGE_LIST",
  payload: chargeList,
});

export const updateDeliveryChargeList = (chargeList) => ({
  type: "UPDATE_DELIVERY_CHARGE_LIST",
  payload: chargeList,
});

export const updateExclusivePromotionChargeList = (chargeList) => ({
  type: "UPDATE_EXCLUSIVE_PROMOTION_CHARGE_LIST",
  payload: chargeList,
});

export const updateConcurrentPromotionChargeList = (chargeList) => ({
  type: "UPDATE_CONCURRENT_PROMOTION_CHARGE_LIST",
  payload: chargeList,
});

export const updateRequiredDeliveryCharges = (charges) => ({
  type: "UPDATE_REQUIRED_DELIVERY_CHARGES",
  payload: charges,
});

export const updateRequiredSurchargeCharges = (charges) => ({
  type: "UPDATE_REQUIRED_SURCHARGE_CHARGES",
  payload: charges,
});

export const updateRequiredExclusivePromotionCharges = (charges) => ({
  type: "UPDATE_REQUIRED_EXCLUSIVE_PROMOTION_CHARGES",
  payload: charges,
});

export const updateRequiredConcurrentPromotionCharges = (charges) => ({
  type: "UPDATE_REQUIRED_CONCURRENT_PROMOTION_CHARGES",
  payload: charges,
});

export const clearRequiredDeliveryCharges = (charges) => ({
  type: "CLEAR_REQUIRED_DELIVERY_CHARGES",
  payload: charges,
});

export const clearRequiredSurchargeCharges = (charges) => ({
  type: "CLEAR_REQUIRED_SURCHARGE_CHARGES",
  payload: charges,
});

export const clearRequiredExclusivePromotionCharges = (charges) => ({
  type: "CLEAR_REQUIRED_EXCLUSIVE_PROMOTION_CHARGES",
  payload: charges,
});

export const clearRequiredConcurrentPromotionCharges = (charges) => ({
  type: "CLEAR_REQUIRED_CONCURRENT_PROMOTION_CHARGES",
  payload: charges,
});

export const updateCSRFToken = (csrfToken) => ({
  type: "UPDATE_CSRF_TOKEN",
  payload: csrfToken,
});

export const updateProductTypeList = (productTypes) => ({
  type: "UPDATE_PRODUCT_TYPE_LIST",
  payload: productTypes,
});

export const updateSelectedProductType = (productType) => ({
  type: "UPDATE_SELECTED_PRODUCT_TYPE",
  payload: productType,
});

export const updateRouteDetails = (routeDetails) => ({
  type: "UPDATE_ROUTE_DETAILS",
  payload: routeDetails,
});

export const updateBaseManualPricing = (baseManualPricing) => ({
  type: "UPDATE_BASE_MANUAL_PRICING",
  payload: baseManualPricing,
});

export const updateAgentPolicyManualPricing = (agentPolicyManualPricing) => ({
  type: "UPDATE_AGENT_POLICY_MANUAL_PRICING",
  payload: agentPolicyManualPricing,
});

export const updateExistingOrder = (existingOrder) => ({
  type: "UPDATE_EXISTING_ORDER",
  payload: existingOrder,
});

export const updateExistingOrderCartItems = (existingOrderCartItems) => ({
  type: "UPDATE_EXISTING_ORDER_CART_ITEMS",
  payload: existingOrderCartItems,
});
