import React, { useState } from "react";
import "./PaymentCard.css";

function PaymentCard({ food, isNegative, hidePrice }) {
  const { title, price, quantity, category, brand } = food;

  const totalPrice = price * quantity;

  return (
    <div className="payment__card">
      <div className="payment__image__container">🍼</div>

      <div className="payment__card__title">
        <div className="payment__title">
          <span className="payment__card_quantity">{quantity}x</span> {brand}{" "}
          {category} {title}
        </div>
      </div>

      {hidePrice ? (
        ""
      ) : (
        <div className="payment__card__total">
          <span className="payment__card__price">
            {isNegative ? <span>-</span> : ""}${Math.abs(totalPrice).toFixed(2)}
          </span>
        </div>
      )}
    </div>
  );
}

export default PaymentCard;
