import React, { useEffect, useState } from "react";
import "./PaymentForm.css";
import TelegramText from "../Text/TelegramText";
import TelegramInput from "../Input/TelegramInput";
import { FaBomb } from "react-icons/fa6";

const PaymentForm = (props) => {
  const [manualPaymentCost, setManualPaymentCost] = useState(
    props.totalCostValue
  );

  useEffect(() => {
    setManualPaymentCost(props.totalCostValue);
  }, [props.totalCostValue]);

  return (
    <div {...props} className={"PaymentForm " + props.className}>
      <TelegramText className={"telegramSubtitle"}>
        <FaBomb /> {props.title} (${props.storeTotalCost.toFixed(2)})
      </TelegramText>
      <TelegramInput
        type="number"
        step="0.01"
        placeholder={props.title}
        value={manualPaymentCost}
        onChange={(e) => props.onChangeTotalCost(e.target.value)}
      />
    </div>
  );
};

export default PaymentForm;
