import React, { useEffect, useState } from "react";
import "../../App.css";

import { useTelegram } from "../../hooks/useTelegram";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import { miyagi } from "ldrs";
import { helix } from "ldrs";

import LoginCard from "../../components/kit/LoginCard/LoginCard";

import { authenticateUser } from "../../db/db";
import Loader from "../../components/kit/Loader/Loader";
import Modal from "../../components/kit/Modal/Modal";

const Main = () => {
  const { webApp, user, executeMethod } = useTelegram();
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track authenticated state
  const [authAgent, setAgent] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [completeAuth, setCompleteAuth] = useState(false);
  const [storeActivity, setStoreActivity] = useState(false);

  const userId = user?.id || "";

  useEffect(() => {
    const authenticateAndUpdateState = async () => {
      setIsAuthenticating(true);
      if (userId) {
        const { user: authUser, store } = await authenticateUser(userId);
        if (authUser?.agent) {
          setIsAuthenticated(true);
          setAgent(authUser?.agent);
        }

        if (store) {
          setStoreActivity(store);
        }
      }

      setIsAuthenticating(false);
      setCompleteAuth(true);
    };

    if (!completeAuth) {
      authenticateAndUpdateState();
    }
  }, [userId, webApp, completeAuth]);

  const onLogoutClick = () => {
    setIsAuthenticated(false);
    setAgent("");
  };

  miyagi.register();
  helix.register();

  return (
    <TelegramScreen showbackbutton={0} className={"parent-container"}>
      {!isAuthenticating ? (
        <LoginCard
          userId={userId}
          webApp={webApp}
          executeMethod={executeMethod}
          isAuthenticated={isAuthenticated}
          authAgent={authAgent}
          storeActivity={storeActivity}
          onLogoutClick={onLogoutClick}
        />
      ) : (
        <Loader title="Authenticating" />
      )}
    </TelegramScreen>
  );
};

export default Main;
