import React, { useState } from "react";
import TelegramInput from "../Input/TelegramInput";
import TelegramButton from "../Button/TelegramButton";
import "./TelegramMiniForm.css";
import TelegramText from "../Text/TelegramText";

const TelegramMiniForm = (props) => {
  const [input, setInput] = useState("");

  const onChangeInput = (e) => {
    const inputValue = e.target.value;
    setInput(inputValue);

    // Invoke the onChange prop passed from the parent component
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const onButtonClick = () => {
    props.onSubmit(input);
  };

  return (
    <div {...props} className={"telegramMiniForm " + props.className}>
      <TelegramText className={"telegramSubtitle"}>
        {props.fieldlabel}
      </TelegramText>

      <TelegramInput
        type="text"
        placeholder={props.fieldhint}
        value={input}
        onChange={onChangeInput}
      />
      <TelegramText className={"telegramHint"}>
        {props.fielddescription}
      </TelegramText>

      {/* <TelegramButton onClick={onButtonClick}>
        {props.buttonlabel}
      </TelegramButton> */}
    </div>
  );
};

export default TelegramMiniForm;
