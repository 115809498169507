import React from "react";
import "./Loader.css";

import { miyagi } from "ldrs";
import { helix } from "ldrs";
import TelegramText from "../Text/TelegramText";

const Loader = ({ title }) => {
  miyagi.register();
  helix.register();
  return (
    <div className="loading-popup">
      <div className="loading-popup-content">
        <l-helix size="45" speed="2.5" color="white"></l-helix>
        <TelegramText className="telegramSubtitle">{title}</TelegramText>
      </div>
    </div>
  );
};

export default Loader;
