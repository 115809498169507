import React, { useState } from "react";
import "./ItemButton.css";
import { FaArrowRight, FaEarthAmericas, FaMoon } from "react-icons/fa6";
import { FaSun } from "react-icons/fa";
import TelegramText from "../Text/TelegramText";

function ItemButton({ item, handleButtonClick, type }) {
  const { title, image, descr } = item;

  const handleClick = () => {
    handleButtonClick(title);
  };

  return (
    <div className="itemcard">
      <div className="btn-container">
        <button
          className={"telegramButton item__card__button"}
          onClick={handleClick}
        >
          <div className="item__content">
            <div className="image__container">
              {type === "brand" ? (
                <FaSun />
              ) : type === "category" ? (
                <FaMoon />
              ) : (
                <FaEarthAmericas />
              )}
              {/* <img src={image} alt={title} /> */}
            </div>
            <TelegramText className="item__title">
              {descr}
            </TelegramText>
            <div className="item__icon">
              <FaArrowRight />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default ItemButton;
