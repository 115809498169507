import React, { useCallback, useEffect } from "react";
import { useTelegram } from "../../../hooks/useTelegram";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import { useNavigate } from "react-router-dom";
import { PATH_ORDER_REVIEW } from "../../../constants/Paths";
import OrderAmendmentForm from "../../../components/app/server/OrderAmendmentForm";

const OrderAmendmentScreen = () => {
  const { webApp } = useTelegram();
  const navigate = useNavigate();

  const onBackClick = useCallback(() => {
    navigate(PATH_ORDER_REVIEW, {
      replace: true,
    });
  }, [navigate]);

  useEffect(() => {
    webApp.ready();
    webApp.BackButton.show();
    webApp.BackButton.onClick(onBackClick);

    return () => {
      webApp.BackButton.offClick(onBackClick);
    };
  }, [webApp, onBackClick]);

  return (
    <TelegramScreen className="full-height">
      <OrderAmendmentForm pageTitle="EDIT ORDER" />
    </TelegramScreen>
  );
};

export default OrderAmendmentScreen;
