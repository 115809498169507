// reducers.js
import { combineReducers } from "redux";

const initialState = {
  cartItems: [],
};
// Define individual reducers
const cartItemsReducer = (state = initialState, action) => {
  // Handle actions related to cartItems
  switch (action.type) {
    case "ADD_TO_CART":
      const { item, quantity: newAddQty } = action.payload;
      const addItemId = item.id;
      const addItem = state.cartItems.find((item) => item.id === addItemId);

      if (addItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((item) =>
            item.id === addItemId
              ? { ...item, quantity: item.quantity + newAddQty }
              : item
          ),
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, { ...item, quantity: 1 }],
        };
      }
    case "REMOVE_FROM_CART":
      const { item: payloadItem, quantity: newSubQty } = action.payload;
      const removeItemId = payloadItem.id;
      const removeItem = state.cartItems.find(
        (item) => item.id === removeItemId
      );

      if (removeItem.quantity > 1) {
        return {
          ...state,
          cartItems: state.cartItems.map((item) =>
            item.id === removeItemId
              ? { ...item, quantity: item.quantity - newSubQty }
              : item
          ),
        };
      } else {
        return {
          ...state,
          cartItems: state.cartItems.filter((item) => item.id !== removeItemId),
        };
      }
    case "REMOVE_FULL_ITEM_FROM_CART":
      const { id: removeFullItemId } = action.payload;
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item.id !== removeFullItemId
        ),
      };
    case "CLEAR_CART":
      return {
        ...state,
        cartItems: [],
      };
    case "REPLACE_CART":
      return {
        ...state,
        cartItems: action.payload, // Replace cart items with the new items
      };
    // ...
    default:
      return state;
  }
};

const selectedBrandReducer = (state = null, action) => {
  // Handle actions related to selectedBrand
  switch (action.type) {
    case "UPDATE_SELECTED_BRAND":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const selectedCategoryReducer = (state = null, action) => {
  // Handle actions related to selectedCategory
  switch (action.type) {
    case "UPDATE_SELECTED_CATEGORY":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const BrandListReducer = (state = null, action) => {
  // Handle actions related to selectedCategory
  switch (action.type) {
    case "UPDATE_BRAND_LIST":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const CategoryListReducer = (state = null, action) => {
  // Handle actions related to selectedCategory
  switch (action.type) {
    case "UPDATE_CATEGORY_LIST":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const ProductListReducer = (state = null, action) => {
  // Handle actions related to selectedCategory
  switch (action.type) {
    case "UPDATE_PRODUCT_LIST":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const UserProfileReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_USER_PROFILE":
      return action.payload;
    case "UPDATE_USER_PROFILE_DETAILS":
      return {
        ...state,
        contactname: action.payload.contactname,
        contactphone: action.payload.contactphone,
        postalcode: action.payload.postalcode,
      };
    // ...
    default:
      return state;
  }
};

const SessionTokenReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_SESSION_TOKEN":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const ThankYouMessageReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_THANKYOU_MESSAGE":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const AgentReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_AGENT":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const DeliveryTimeSlotReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_DELIVERY_TIME_SLOT":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const SelectedTimeSlotReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_SELECTED_TIME_SLOT":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const TotalCostReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_TOTAL_COST":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const CheckoutCommentReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_CHECKOUT_COMMENT":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const SurchargeChargeListReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_SURCHARGE_CHARGE_LIST":
      return action.payload;
    case "CLEAR_SURCHARGE_CHARGE_LIST":
      return [];
    // ...
    default:
      return state;
  }
};

const DeliveryChargeListReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_DELIVERY_CHARGE_LIST":
      return action.payload;
    case "CLEAR_DELIVERY_CHARGE_LIST":
      return [];
    // ...
    default:
      return state;
  }
};

const ExclusivePromotionChargeListReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_EXCLUSIVE_PROMOTION_CHARGE_LIST":
      return action.payload;
    case "CLEAR_EXCLUSIVE_PROMOTION_CHARGE_LIST":
      return [];
    // ...
    default:
      return state;
  }
};

const ConcurrentPromotionChargeListReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_CONCURRENT_PROMOTION_CHARGE_LIST":
      return action.payload;
    case "CLEAR_CONCURRENT_PROMOTION_CHARGE_LIST":
      return [];
    // ...
    default:
      return state;
  }
};

const RequiredDeliveryChargesReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_REQUIRED_DELIVERY_CHARGES":
      return action.payload;
    case "CLEAR_REQUIRED_DELIVERY_CHARGES":
      return [];
    // ...
    default:
      return state;
  }
};

const RequiredSurchargeChargesReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_REQUIRED_SURCHARGE_CHARGES":
      return action.payload;
    case "CLEAR_REQUIRED_SURCHARGE_CHARGES":
      return [];
    // ...
    default:
      return state;
  }
};

const RequiredExclusivePromotionChargesReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_REQUIRED_EXCLUSIVE_PROMOTION_CHARGES":
      return action.payload;
    case "CLEAR_REQUIRED_EXCLUSIVE_PROMOTION_CHARGES":
      return [];
    // ...
    default:
      return state;
  }
};

const RequiredConcurrentPromotionChargesReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_REQUIRED_CONCURRENT_PROMOTION_CHARGES":
      return action.payload;
    case "CLEAR_REQUIRED_CONCURRENT_PROMOTION_CHARGES":
      return [];
    // ...
    default:
      return state;
  }
};

const CSRFTokenReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_CSRF_TOKEN":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const ProductTypeListReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_PRODUCT_TYPE_LIST":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const SelectedProductTypeReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_SELECTED_PRODUCT_TYPE":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const RouteDetailsReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_ROUTE_DETAILS":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const BaseManualPricingReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_BASE_MANUAL_PRICING":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const AgentPolicyManualPricingReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_AGENT_POLICY_MANUAL_PRICING":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const CurrentTimeSlotOrdersReducer = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_CURRENT_TIME_SLOT_ORDERS":
      return action.payload;
    // ...
    default:
      return state;
  }
};

const ExistingOrder = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_EXISTING_ORDER":
      return action.payload;
    // ...
    default:
      return state;
  }
};
const ExistingOrderCartItems = (state = null, action) => {
  // Handle actions related to user
  switch (action.type) {
    case "UPDATE_EXISTING_ORDER_CART_ITEMS":
      return action.payload;
    // ...
    default:
      return state;
  }
};

// Combine the reducers into a root reducer
const rootReducer = combineReducers({
  cartItems: cartItemsReducer,
  selectedBrand: selectedBrandReducer,
  selectedCategory: selectedCategoryReducer,
  brands: BrandListReducer,
  categories: CategoryListReducer,
  products: ProductListReducer,
  userProfile: UserProfileReducer,
  sessionToken: SessionTokenReducer,
  thankYouMessage: ThankYouMessageReducer,
  agent: AgentReducer,
  deliveryTimeSlots: DeliveryTimeSlotReducer,
  totalCost: TotalCostReducer,
  checkoutComment: CheckoutCommentReducer,
  selectedTimeSlot: SelectedTimeSlotReducer,
  surchargeChargeList: SurchargeChargeListReducer,
  deliveryChargeList: DeliveryChargeListReducer,
  exclusivePromotionChargeList: ExclusivePromotionChargeListReducer,
  concurrentPromotionChargeList: ConcurrentPromotionChargeListReducer,
  requiredDeliveryCharges: RequiredDeliveryChargesReducer,
  requiredSurchargeCharges: RequiredSurchargeChargesReducer,
  requiredExclusivePromotionCharges: RequiredExclusivePromotionChargesReducer,
  requiredConcurrentPromotionCharges: RequiredConcurrentPromotionChargesReducer,
  csrfToken: CSRFTokenReducer,
  selectedProductType: SelectedProductTypeReducer,
  productTypeList: ProductTypeListReducer,
  routeDetails: RouteDetailsReducer,
  baseManualPricing: BaseManualPricingReducer,
  agentPolicyManualPricing: AgentPolicyManualPricingReducer,
  currentTimeSlotOrders: CurrentTimeSlotOrdersReducer,
  existingOrderCartItems: ExistingOrderCartItems,
  existingOrder: ExistingOrder,
});

export default rootReducer;
