import React, { useCallback, useEffect, useState } from "react";
import "../../../App.css";

import { useTelegram } from "../../../hooks/useTelegram";
import { useNavigate } from "react-router-dom";
import { PATH_ORDER_REVIEW, PATH_PRODUCT_TYPE } from "../../../constants/Paths";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/kit/Loader/Loader";
import {
  replaceCart,
  updateExistingOrder,
  updateExistingOrderCartItems,
} from "../../../components/kit/Redux/Actions";
import OrderButton from "../../../components/kit/OrderButton/OrderButton";
import TelegramDataBlock from "../../../components/kit/DataBlock/TelegramDataBlock";
import TelegramDetailedButton from "../../../components/kit/DetailedButton/TelegramDetailedButton";
import TelegramText from "../../../components/kit/Text/TelegramText";
import Divider from "../../../components/kit/Divider/Divider";
// import Cart from "../../components/kit/Cart/Cart";

const { retrieveOrder } = require("../../../db/db");

const OrderManagementScreen = () => {
  const { webApp, user } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false); // State to track loading state

  const sessionToken = useSelector((state) => state.sessionToken);
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const currentTimeSlotOrders = useSelector(
    (state) => state.currentTimeSlotOrders
  );

  const onNewOrderClick = () => {
    navigate(PATH_PRODUCT_TYPE, {
      replace: true,
    });
  };

  const onExistingOrderClick = useCallback(
    async (orderId) => {
      try {
        // setIsLoading(true);

        if (orderId) {
          // Let's call the API, OrderRetrieval to get the order details
          // Once we get the details, the API data should return with CartItems, CustomerDetails, DeliveryDetails
          // Step 1 Call API, Step 2 Update Redux Store, Step 3 Navigate to Checkout Screen
          const userProfile = {
            userId: user?.id,
            userName: user?.username,
          };
          const payload = {
            OrderProfile: {
              UserProfile: userProfile,
              OrderId: orderId,
            },
          };

          const result = await retrieveOrder(sessionToken, payload);

          const { Header, Body } = result.Order;

          const orderHeader = {
            id: Header.id,
            orderNo: Header.orderno,
            orderDate: Header.orderdate,
            customerName: Header.customername,
            customerContact: Header.customercontact,
            customerAddress: Header.customeraddress,
            deliveryDate: Header.deliverydate,
            agentId: Header.agentid,
            routeId: Header.routeid,
            timeSlot: {
              datetime: Header.deliverydate,
              timeperiod: Header.timeperiod,
              timerange: Header.timerange,
              deliveryfromhour: Header.deliveryfromhour,
              deliverytohour: Header.deliverytohour,
            },
          };

          const orderDetails = Body.map((cartItem) => ({
            id: cartItem.id,
            title: cartItem.name,
            price: parseFloat(cartItem.salesprice) || 999,
            category: cartItem.category,
            brand: cartItem.brand,
            type: cartItem.type,
            orderedQty: parseInt(cartItem.expectedqty) || 0,
            availableQty:
              parseInt(cartItem.availableqty) +
                parseInt(cartItem.expectedqty) || 0,
            quantity: parseInt(cartItem.expectedqty) || 0,
          }));

          // const { cartItems, customerDetails } = orderDetails;
          dispatch(updateExistingOrder(orderHeader));
          dispatch(updateExistingOrderCartItems(orderDetails));
          dispatch(replaceCart(orderDetails));
          // dispatch(updateUserProfile(customerDetails));
          navigate(PATH_ORDER_REVIEW, {
            replace: true,
          });
        }
      } catch (error) {
        alert("Error: " + error);
        throw new Error(error);
      }
    },
    [navigate, dispatch, sessionToken, user]
  );

  useEffect(() => {
    webApp.ready();
    webApp.MainButton.hide();

    webApp.BackButton.hide();

    return () => {
      webApp.MainButton.offClick();
    };
  }, [webApp, cartItems]);

  return (
    <TelegramScreen>
      {!isLoading ? (
        <div>
          {/* <TelegramHeader>
            <TelegramText className={"telegramTitle"}>
              HEADQUARTERS
            </TelegramText>
          </TelegramHeader> */}
          <div className="new_order_container">
            <OrderButton
              title={"NEW ORDER"}
              className={`new__order__button`}
              handleButtonClick={onNewOrderClick}
              type="create"
            />
          </div>
          <Divider />
          <div className="edit_order_container">
            {Object.keys(currentTimeSlotOrders).map((deliveryDate) => (
              <div key={deliveryDate} className="time_slot">
                <TelegramText className="edit_order_time_slot_title">
                  {deliveryDate}
                </TelegramText>
                {currentTimeSlotOrders[deliveryDate].map((order) => (
                  <OrderButton
                    title={`${order.orderno} | ${order.customercontact}`}
                    key={order.id}
                    className="existing__order__button"
                    handleButtonClick={() => onExistingOrderClick(order.id)}
                    type="edit"
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Loader title="Retrieving.." />
      )}
    </TelegramScreen>
  );
};

export default OrderManagementScreen;
