import React, { useCallback, useEffect, useState } from "react";
import { useTelegram } from "../../../hooks/useTelegram";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../../components/kit/Header/TelegramHeader";
import TelegramText from "../../../components/kit/Text/TelegramText";
import { useNavigate } from "react-router-dom";
import CheckoutCard from "../../../components/kit/CheckoutCard/CheckoutCard";
import { PATH_PAYMENT_INFO, PATH_PRODUCT } from "../../../constants/Paths";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  removeFromCart,
  updateTotalCost,
} from "../../../components/kit/Redux/Actions";

const CheckoutScreen = () => {
  const { webApp, user } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cartItems = useSelector((state) => state.cartItems.cartItems);
  // alert(`Checkout: ${JSON.stringify(cartItems)}`);

  const checkoutComment = useSelector((state) => state.checkoutComment);
  const totalPrice = cartItems.reduce((a, c) => a + c.price * c.quantity, 0);
  const totalItems = cartItems.reduce((a, c) => a + c.quantity, 0);

  const onMainClick = useCallback(() => {
    dispatch(updateTotalCost(totalPrice));

    navigate(PATH_PAYMENT_INFO, {
      replace: true,
    });
  }, [navigate, dispatch, totalPrice]);

  const onBackClick = useCallback(() => {
    navigate(PATH_PRODUCT, {
      replace: true,
    });
  }, [navigate]);

  useEffect(() => {
    webApp.ready();
    webApp.MainButton.onClick(onMainClick);
    webApp.BackButton.onClick(onBackClick);

    webApp.MainButton.text = `CHECKOUT`;
    // webApp.MainButton.color = "#1c54bd";
    webApp.MainButton.color = "#31b545";
    // webApp.MainButton.showProgress(true);

    webApp.BackButton.show();

    if (cartItems.length > 0) {
      webApp.MainButton.show();
    } else {
      webApp.MainButton.hide();
    }

    return () => {
      webApp.MainButton.offClick(onMainClick);
      webApp.BackButton.offClick(onBackClick);
    };
  }, [
    webApp,
    cartItems,
    onMainClick,
    onBackClick,
    totalPrice,
    checkoutComment,
  ]);

  const onAdd = (item, newAddQty) => {
    dispatch(addToCart(item, newAddQty));
  };

  const onRemove = (item, newSubQty) => {
    dispatch(removeFromCart(item, newSubQty));
  };

  return (
    <TelegramScreen>
      <div>
        <TelegramHeader>
          <TelegramText className={"telegramTitle"}>YOUR TROLLEY</TelegramText>
        </TelegramHeader>

        {cartItems.length > 0 ? (
          <div className="checkout__container">
            <div className="checkout__cards__container">
              {cartItems.map((item) => {
                return (
                  <CheckoutCard
                    food={item}
                    quantity={item.quantity}
                    availableQty={item.availableQty}
                    key={item.id}
                    onAdd={onAdd}
                    onRemove={onRemove}
                  />
                );
              })}
            </div>
            <div className="checkout__total__items__container">
              <TelegramText>Total Items:</TelegramText>
              <TelegramText>
                <span>{totalItems}</span>
              </TelegramText>
            </div>
            <div className="checkout__cost__container">
              <TelegramText>Total Cost:</TelegramText>
              <TelegramText>
                <span>${totalPrice.toFixed(2)}</span>
              </TelegramText>
            </div>
          </div>
        ) : (
          <TelegramText className={"centered-text"}>Empty Cart</TelegramText>
        )}
      </div>
    </TelegramScreen>
  );
};

export default CheckoutScreen;
