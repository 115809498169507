import React, { useCallback, useEffect } from "react";
import { useTelegram } from "../../../hooks/useTelegram";
import { useSelector } from "react-redux";

const PaymentHandler = (props) => {
  const { webApp } = useTelegram();

  const userProfile = useSelector((state) => state.userProfile);
  const selectedTimeSlot = useSelector((state) => state.selectedTimeSlot);
  const checkoutComment = useSelector((state) => state.checkoutComment);

  const onButtonClick = useCallback(() => {
    // Perform validation here
    try {
      props.onSubmit({
        name: userProfile.contactname,
        phone: userProfile.contactphone,
        address: userProfile.postalcode,
        timeSlot: selectedTimeSlot,
        comments: checkoutComment,
      });
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  }, [
    props,
    selectedTimeSlot,
    userProfile.contactname,
    userProfile.contactphone,
    userProfile.postalcode,
    checkoutComment,
  ]);

  useEffect(() => {
    webApp.ready();
    webApp.MainButton.onClick(onButtonClick);

    webApp.MainButton.text = props.buttonlabel;
    webApp.MainButton.color = "#31b545";
    webApp.MainButton.show();
    // webApp.MainButton.showProgress(true);

    return () => {
      webApp.MainButton.offClick(onButtonClick);
    };
  }, [webApp, onButtonClick]);
  useEffect(() => {}, []);

  return <></>;
};

export default PaymentHandler;
