import React, { useEffect } from "react";
import "./TelegramScreen.css";
import { useTelegram } from "../../../hooks/useTelegram";

const TelegramScreen = (props) => {
  const { webApp } = useTelegram();
  useEffect(() => {
    // if (props.showbackbutton === 1) {
    //   webApp.BackButton.show();
    // } else {
    //   webApp.BackButton.hide();
    // }
  }, [
    webApp,
    // , props.showbackbutton
  ]);

  return <div {...props} className={"telegramScreen " + props.className} />;
};

export default TelegramScreen;
