import React, { useCallback, useEffect } from "react";
import "../../App.css";

import TelegramText from "../../components/kit/Text/TelegramText";
import { useTelegram } from "../../hooks/useTelegram";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import TelegramHeader from "../../components/kit/Header/TelegramHeader";

const ThankYouScreen = () => {
  const { webApp, executeMethod } = useTelegram();

  const onMainClick = useCallback(() => {
    executeMethod("close", webApp.close, true);
  }, [executeMethod]);

  const onBackClick = useCallback(() => {
    executeMethod("close", webApp.close, true);
  }, [executeMethod]);

  useEffect(() => {
    webApp.ready();
    webApp.disableClosingConfirmation();
    webApp.MainButton.hideProgress(true);
    webApp.MainButton.enable();

    webApp.MainButton.onClick(onMainClick);
    webApp.BackButton.onClick(onBackClick);

    webApp.MainButton.text = "THANK YOU";
    webApp.MainButton.color = "#31b545";

    webApp.MainButton.show();
    webApp.BackButton.hide();

    if (!webApp.isExpanded) {
      executeMethod("expand", webApp.expand, true);
    }
    return () => {
      webApp.MainButton.offClick(onMainClick);
      webApp.BackButton.offClick(onBackClick);
    };
  }, [webApp, onMainClick, onBackClick]);

  return (
    <TelegramScreen showbackbutton={0} className={"parent-container"}>
      <TelegramHeader>
        <TelegramText className={"header-text"}>
          ⭐️ Thank you for your order ⭐️
        </TelegramText>
      </TelegramHeader>
      <TelegramText className={`centered-text big-text`}>
        Do prepare cash, only cash accepted
      </TelegramText>
      <TelegramText className={`centered-text big-text`}>
        Driver will contact 10-15mins before arrival
      </TelegramText>
    </TelegramScreen>
  );
};

export default ThankYouScreen;
