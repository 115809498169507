import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../../hooks/useTelegram";
import { useDispatch, useSelector } from "react-redux";
import TelegramScreen from "../../kit/Screen/TelegramScreen";
import PaymentCard from "../../kit/PaymentCard/PaymentCard";
import TelegramText from "../../kit/Text/TelegramText";
import { validateAvailableInventory } from "../../../logic/server/OrderFunction";
import { removeFullItemFromCart } from "../../kit/Redux/Actions";
import TelegramHeader from "../../kit/Header/TelegramHeader";
import PaymentForm from "../../kit/PaymentForm/PaymentForm";
import PromotionCard from "../../kit/PromotionCard/PromotionCard";
import PaymentHandler from "../../kit/PaymentHandler/PaymentHandler";
import { submitEditOrder } from "../../utils/submit-edit-order";
import { filterCartDifferences } from "../../utils/review-order";
import Divider from "../../kit/Divider/Divider";

// Define the function outside of your component
function isDeliveryWaiverPromotionApplied(promotions) {
  // Assuming `promotions` is an array of the promotions applied to the order
  for (let i = 0; i < promotions.length; i++) {
    if (promotions[i].chargedescription.includes("FREE DELIVERY")) {
      return true;
    }
  }
  return false;
}

/**
 * /messages endpoint is used to send messages to the bot server using a REST API.
 * The bot will receive the message and echo it in the chat.
 *
 * @see sendMessageToServer
 */
const OrderAmendmentForm = ({ pageTitle }) => {
  const { webApp, user } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const sessionToken = useSelector((state) => state.sessionToken);
  const agent = useSelector((state) => state.agent);
  const cartBaseCost = cartItems.reduce((a, c) => a + c.price * c.quantity, 0);
  const requiredDeliveryCharges = useSelector(
    (state) => state.requiredDeliveryCharges
  );
  const requiredSurchargeCharges = useSelector(
    (state) => state.requiredSurchargeCharges
  );
  const requiredExclusivePromotionCharges = useSelector(
    (state) => state.requiredExclusivePromotionCharges
  );

  // const requiredConcurrentPromotionCharges = useSelector(
  //   (state) => state.requiredConcurrentPromotionCharges
  // );
  const baseManualPricingEnabled = useSelector(
    (state) => state.baseManualPricing
  );

  const agentPolicyManualPricingEnabled = useSelector(
    (state) => state.agentPolicyManualPricing
  );

  const existingOrderCartItems = useSelector(
    (state) => state.existingOrderCartItems
  );

  const existingOrder = useSelector((state) => state.existingOrder);

  const routeDetails = useSelector((state) => state.routeDetails);

  const deliveryCost =
    requiredDeliveryCharges.length > 0
      ? parseFloat(requiredDeliveryCharges[0].chargeamount)
      : 0; // Default to 0
  const surchargeCost =
    requiredSurchargeCharges.length > 0
      ? parseFloat(requiredSurchargeCharges[0].chargeamount)
      : 0; // Default to 0

  // Calculate promotionCost by summing up chargeamount values from requiredPromotionCharges
  const exclusivePromotionCost = requiredExclusivePromotionCharges.reduce(
    (total, charge) => {
      return total + parseFloat(charge.chargeamount);
    },
    0
  ); // Start with total 0

  const agentId = agent.id; // [0] - Id, [1] Code

  // const [buttonDisabled, setButtonDisabled] = useState("false");
  const [buttonText, setButtonText] = useState();
  const [paymentCost, setPaymentCost] = useState(0);
  const [reduceCost, setReduceCost] = useState(0);
  const [updatedCartItems, setUpdatedCartItems] = useState([]);
  const [cartItemsToRemove, setCartItemsToRemove] = useState([]);

  // Popup for Partial Order Fulfilment
  const [showPartialOrderFulfilmentPopup, setShowPartialOrderFulfilmentPopup] =
    useState(false); // State variable for controlling the visibility of the confirm popup
  const [isPartialOrderFulfilmentNeeded, setIsPartialOrderFulfilmentNeeded] =
    useState(false); // State variable for controlling the visibility of the confirm popup

  // Popup for Manual Pricing
  const [showManualPricingPopup, setShowManualPricingPopup] = useState(false); // State variable for controlling the visibility of the confirm popup
  const [isManualPricingNeeded, setIsManualPricingNeeded] = useState(false); // State variable for controlling the visibility of the confirm popup
  const [manualPaymentCost, setManualPaymentCost] = useState(0);
  const [isDeliveryWaived, setIsDeliveryWaived] = useState(false);
  const [additionalItems, setAdditionalItems] = useState(null);
  const [removedOrReducedItems, setRemovedOrReducedItems] = useState(null);

  const [additionalItemsCost, setAdditionalItemsCost] = useState(0);
  const [reductionItemsCost, setReductionItemsCost] = useState(0);

  // const storeTotalCost =
  //   cartBaseCost +
  //   deliveryCost +
  //   surchargeCost +
  //   exclusivePromotionCost -
  //   //concurrentPromotionCost -
  //   reduceCost;

  const storeTotalCost =
    additionalItemsCost +
    reductionItemsCost +
    exclusivePromotionCost -
    reduceCost;

  // This id will be used by our backend to send messages to the chat with the user
  let queryId = webApp.initDataUnsafe?.query_id;

  const onMainClick = async ({ name, phone, address, timeSlot, comments }) => {
    try {
      // Enabling/disabling the button
      webApp.BackButton.hide();
      webApp.MainButton.disable();
      webApp.MainButton.showProgress(false);

      // Calculate final costs
      const finalBaseCost = manualPaymentCost
        ? parseFloat(manualPaymentCost)
        : parseFloat(paymentCost);
      // const finalDeliveryCost = parseFloat(deliveryCost);
      // const finalSurchargeCost = parseFloat(surchargeCost);
      const finalDiscountAmount = parseFloat(exclusivePromotionCost);

      const finalAdditionalItemsCost = additionalItemsCost;
      const finalReductionItemsCost = reductionItemsCost;

      if (!queryId) {
        throw new Error("Please contact the administrator.");
      }

      const utils = { webApp, sessionToken, navigate };

      const userDetails = {
        userId: user?.id,
        userName: user?.username,
        agentId,
        routeDetails,
        customerName: name,
        customerContact: phone,
        customerAddress: address,
        timeSlot,
        customerComments: comments,
      };

      const orderCosts = {
        isDeliveryWaived,
        discountAmount: finalDiscountAmount,
        additionalCost: finalAdditionalItemsCost,
        reductionCost: finalReductionItemsCost,
        paymentCost: finalBaseCost,
        storeTotalCost: storeTotalCost,
        charges: {
          deliveryCharges: requiredDeliveryCharges,
          surchargeCharges: requiredSurchargeCharges,
          promotionCharges: requiredExclusivePromotionCharges,
        },
      };

      if (!isPartialOrderFulfilmentNeeded) {
        const { isAvailable, updatedCartItems, itemsToRemove } =
          await validateAvailableInventory(
            sessionToken,
            cartItems,
            existingOrderCartItems
          );

        if (!isAvailable) {
          // Update the state variables with the original cart items and the items to remove

          alert(
            "Some items in your cart are unavailable.\n\nPlease check and confirm to proceed anyways."
          );

          setUpdatedCartItems(updatedCartItems);
          setCartItemsToRemove(itemsToRemove);

          const costToReduce = itemsToRemove.reduce(
            (a, c) => a + c.price * c.quantity,
            0
          );

          setReduceCost(costToReduce);
          webApp.MainButton.hideProgress(true);
          // Show the confirm popup
          setShowPartialOrderFulfilmentPopup(true);
          setIsPartialOrderFulfilmentNeeded(true);
          setButtonText(`Partial Fulfillment`);
          return;
        }

        // Let's check whether we allow manual updating of total cost
        if (baseManualPricingEnabled || agentPolicyManualPricingEnabled) {
          if (!isManualPricingNeeded) {
            webApp.MainButton.hideProgress(true);
            // setButtonText(`Manual Pay`);
            setManualPaymentCost(paymentCost);
            // Show the confirm popup
            setIsManualPricingNeeded(true);
            setShowManualPricingPopup(true);

            return;
          }

          let lowestCost = Math.min(storeTotalCost, 0);

          if (finalBaseCost < lowestCost) {
            webApp.MainButton.hideProgress(true);
            alert("Please input a valid amount.");
            return;
          }

          if (agentPolicyManualPricingEnabled) {
            if (finalBaseCost < storeTotalCost) {
              alert("Amount cannot be lower than store cost.");
              webApp.MainButton.hideProgress(true);
              return;
            }
          }
        }

        submitEditOrder(
          utils,
          userDetails,
          orderCosts,
          cartItems,
          existingOrder,
          existingOrderCartItems
        );
      } else {
        // Let's check whether we allow manual updating of total cost
        if (baseManualPricingEnabled || agentPolicyManualPricingEnabled) {
          if (!isManualPricingNeeded) {
            webApp.MainButton.hideProgress(true);
            // setButtonText(`Manual Pay`);
            setManualPaymentCost(paymentCost);

            // Show the confirm popup
            setIsManualPricingNeeded(true);
            setShowManualPricingPopup(true);
            return;
          }

          let lowestCost = Math.min(storeTotalCost, 0);

          if (finalBaseCost < lowestCost) {
            alert("Please input a valid amount.");
            webApp.MainButton.hideProgress(true);
            return;
          }

          if (agentPolicyManualPricingEnabled) {
            if (finalBaseCost < storeTotalCost) {
              alert("Amount cannot be lower than store cost.");
              webApp.MainButton.hideProgress(true);
              return;
            }
          }
        }
        // Since it is after confirmation popup, it is likely there are items in the cart to be removed
        // So let's remove it first before we move on

        alert(
          "Remove the items in the cart items that do not have available quantity"
        );
        updatedCartItems.forEach((item) => {
          if (item.quantity === 0) {
            dispatch(removeFullItemFromCart(item));
          }
        });

        const updatedCartItemsRemoveEmpty = updatedCartItems.filter(
          (item) => item.quantity > 0
        );

        setUpdatedCartItems(updatedCartItemsRemoveEmpty);

        submitEditOrder(
          utils,
          userDetails,
          orderCosts,
          updatedCartItemsRemoveEmpty,
          existingOrder,
          existingOrderCartItems
        );
      }
    } catch (error) {
      // Handle the error gracefully, e.g., show an error message to the user
      webApp.BackButton.show();

      console.error("Error sending message:", error);
      alert(`${error}`);
    }
  };

  // 31/Mar/2024 VE - Moved proceedOrder to OrderFunction
  // sessionToken, name, phone, address, timeSlot, cart, comments, deliveryCost, surchargeCost, paymentCost, agentId, user

  useEffect(() => {
    // 1/Jul/2024 VE - New Logic
    const { addedOrIncreasedItems, removedOrReducedItems } =
      filterCartDifferences(cartItems, existingOrderCartItems);

    if (addedOrIncreasedItems && addedOrIncreasedItems.length > 0) {
      setAdditionalItems(addedOrIncreasedItems);

      const newAdditionalItemsCost = addedOrIncreasedItems.reduce(
        (a, c) => a + c.price * c.quantity,
        0
      );

      setAdditionalItemsCost(newAdditionalItemsCost);
    }

    if (removedOrReducedItems && removedOrReducedItems.length > 0) {
      setRemovedOrReducedItems(removedOrReducedItems);

      const newReductionItemsCost = removedOrReducedItems.reduce(
        (a, c) => a + c.price * c.quantity,
        0
      );

      setReductionItemsCost(newReductionItemsCost);
    }

    // Check if a promotion applies and waives the delivery cost
    if (
      requiredExclusivePromotionCharges?.length > 0 &&
      isDeliveryWaiverPromotionApplied(requiredExclusivePromotionCharges)
    ) {
      setIsDeliveryWaived(true);
    }

    let payCost =
      additionalItemsCost +
      reductionItemsCost +
      exclusivePromotionCost -
      reduceCost;

    setButtonText(`Pay ${payCost < 0 ? "-" : ""}$${payCost}`);
    if (manualPaymentCost) {
      payCost = manualPaymentCost;
      setButtonText(
        `Admin Pay ${payCost < 0 ? "-" : ""}$${Math.abs(paymentCost).toFixed(
          2
        )}`
      );
    } else {
      // setManualPaymentCost(payCost);
    }

    setPaymentCost(payCost);
  }, [
    cartBaseCost,
    deliveryCost,
    surchargeCost,
    exclusivePromotionCost,
    //concurrentPromotionCost,
    paymentCost,
    reduceCost,
    cartItemsToRemove,
    manualPaymentCost,
    requiredExclusivePromotionCharges,
    cartItems,
    existingOrderCartItems,
    additionalItemsCost,
    reductionItemsCost,
  ]);

  const onChangeTotalCost = (newValue) => {
    setManualPaymentCost(newValue);
  };

  return (
    <TelegramScreen>
      <TelegramHeader>
        <TelegramText className={"telegramTitle"}>{pageTitle}</TelegramText>
      </TelegramHeader>
      <div className="payment__cards__container">
        <div className="payment_existing__cart__container">
          {existingOrderCartItems.map((item) => {
            return <PaymentCard food={item} key={item.id} hidePrice={true} />;
          })}
        </div>
        <Divider />
        {additionalItems ? (
          <div className="payment__new__cart__container">
            More
            {additionalItems.map((item) => {
              return <PaymentCard food={item} key={item.id} />;
            })}
          </div>
        ) : (
          ""
        )}

        {removedOrReducedItems ? (
          <div className="payment__remove__cart__container">
            Less
            {removedOrReducedItems.map((item) => {
              return (
                <PaymentCard food={item} key={item.id} isNegative={true} />
              );
            })}
          </div>
        ) : (
          ""
        )}

        {showPartialOrderFulfilmentPopup && (
          <div className="confirm__popup">
            {/* <p>Some items in your cart are unavailable. The following items will
              be removed from your cart:</p> */}
            <div className="unavailable__cart__container">
              {cartItemsToRemove.map((item) => {
                return <PaymentCard food={item} key={item.id} />;
              })}
            </div>
          </div>
        )}
        {requiredExclusivePromotionCharges.length > 0 ? (
          <div className="promotion__cost__container">
            Exclusive Promotions
            {requiredExclusivePromotionCharges.map((promotion) => {
              return <PromotionCard promotion={promotion} />;
            })}
          </div>
        ) : (
          ""
        )}
        <div className="payment__cost__container">
          <h4>Total</h4>
          <span>
            {paymentCost < 0 ? <span>-</span> : ""}$
            {Math.abs(paymentCost).toFixed(2)}
          </span>
        </div>
      </div>
      <PaymentHandler buttonlabel={buttonText} onSubmit={onMainClick} />
      <Divider />
      {showManualPricingPopup && (
        <div className="payment-cost-popup">
          <div className="payment-cost-content">
            <PaymentForm
              // onSubmit={onMainClick}
              onChangeTotalCost={onChangeTotalCost}
              totalCostValue={manualPaymentCost}
              storeTotalCost={storeTotalCost}
              title={"Additional Total"}
            />
          </div>
        </div>
      )}
    </TelegramScreen>
  );
};

export default OrderAmendmentForm;
