import React, { useCallback, useEffect, useState } from "react";
import "../../../App.css";

// import TelegramHeader from "../../../components/kit/Header/TelegramHeader";
import TelegramText from "../../../components/kit/Text/TelegramText";
import { useTelegram } from "../../../hooks/useTelegram";
import { useNavigate } from "react-router-dom";
import {
  PATH_CATEGORY,
  PATH_CHECKOUT,
  PATH_ORDER_REVIEW,
  PATH_PRODUCT_TYPE,
} from "../../../constants/Paths";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import ItemButton from "../../../components/kit/ItemButton/ItemButton";
import TelegramHeader from "../../../components/kit/Header/TelegramHeader";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/kit/Loader/Loader";
import { replaceCart } from "../../../components/kit/Redux/Actions";
// import Cart from "../../../components/kit/Cart/Cart";

const { getCategoryByBrand } = require("../../../db/db");

const BrandScreen = () => {
  const { webApp } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false); // State to track loading state

  const brands = useSelector((state) => state.brands);
  const sessionToken = useSelector((state) => state.sessionToken);
  const selectedProductType = useSelector((state) => state.selectedProductType);
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const existingOrder = useSelector((state) => state.existingOrder);

  const onMainClick = useCallback(
    (selectedBrand) => {
      setIsLoading(true);

      getCategoryByBrand(sessionToken, selectedProductType, selectedBrand)
        .then((data) => {
          dispatch({ type: "UPDATE_SELECTED_BRAND", payload: selectedBrand });
          dispatch({ type: "UPDATE_CATEGORY_LIST", payload: data });

          navigate(PATH_CATEGORY, {
            replace: true,
          });
        })
        .catch((error) => {
          setIsLoading(false);

          // Handle error if necessary
        });
    },
    [navigate, dispatch, selectedProductType, sessionToken]
  );

  const onBackClick = useCallback(() => {
    navigate(PATH_PRODUCT_TYPE, {
      replace: true,
    });
  }, [navigate]);

  const onCartClick = useCallback(() => {
    setIsLoading(true);

    const sortedCartItems = [...cartItems].sort((a, b) => {
      // Sort by Type
      const typeResult = a.type.localeCompare(b.type);
      if (typeResult !== 0) return typeResult;

      // Sort by Brand
      const brandResult = a.brand.localeCompare(b.brand);
      if (brandResult !== 0) return brandResult;

      // Sort by Category
      const categoryResult = a.category.localeCompare(b.category);
      if (categoryResult !== 0) return categoryResult;

      // Sort by Title
      return a.title.localeCompare(b.title);
    });

    dispatch(replaceCart(sortedCartItems));

    if (existingOrder) {
      navigate(PATH_ORDER_REVIEW, { replace: true });
    } else {
      navigate(PATH_CHECKOUT, { replace: true });
    }
  }, [navigate, cartItems, dispatch]);

  useEffect(() => {
    webApp.ready();
    webApp.MainButton.onClick(onCartClick);
    webApp.BackButton.onClick(onBackClick);

    webApp.BackButton.show();
    if (cartItems.length > 0) {
      webApp.MainButton.text = "VIEW ORDER";
      webApp.MainButton.color = "#31b545";
      webApp.MainButton.show();
    } else {
      webApp.MainButton.hide();
    }
    return () => {
      webApp.MainButton.offClick(onCartClick);
      webApp.BackButton.offClick(onBackClick);
    };
  }, [webApp, onMainClick, onBackClick, cartItems, onCartClick]);

  return (
    <TelegramScreen>
      {!isLoading ? (
        <div>
          {brands.length > 0 ? (
            <>
              <TelegramHeader>
                <TelegramText className={"telegramTitle"}>
                  {selectedProductType}
                </TelegramText>
              </TelegramHeader>
              <div className="oneline__cards__container">
                {brands.map((item) => {
                  return (
                    <ItemButton
                      item={item}
                      key={item.title}
                      handleButtonClick={onMainClick}
                      type="brand"
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <TelegramHeader>
                <TelegramText className={"telegramTitle"}>
                  No inventory at the moment
                </TelegramText>
              </TelegramHeader>
            </>
          )}
        </div>
      ) : (
        <Loader title="Rendering..." />
      )}
    </TelegramScreen>
  );
};

export default BrandScreen;
