import React, { useState } from "react";
import "./Card.css";
import Button from "../Button/Button";

function Card({ food, currentCount, onAdd, onRemove }) {
  const [isShaking, setIsShaking] = useState(false);
  const [count, setCount] = useState(currentCount !== 0 ? currentCount : 0);
  const { title, price, availableQty } = food;

  const handleIncrement = () => {
    if (count === availableQty) {
      // Set isShaking to true to trigger the animation
      setIsShaking(true);

      // After a short delay, reset isShaking to false to stop the animation
      setTimeout(() => {
        setIsShaking(false);
      }, 1000); // Adjust the duration of the animation as needed
    } else if (count < availableQty) {
      onAdd(food, 1);
      setCount(count + 1);
    }
  };

  const handleDecrement = () => {
    onRemove(food, 1);
    setCount(count - 1);
  };

  return (
    <div className="card">
      {/* <span className="card__inventory__badge">{availableQty}</span> */}
      <span
        className={`${count !== 0 ? "card__badge" : "card__badge--hidden"} ${
          isShaking ? "shake" : ""
        }`}
      >
        {count}
      </span>
      <div className="product__image__container">
        {/* <img src={Image} alt={title} /> */}
        🍼
        {/* <FaPrescriptionBottle class="gradient-text" /> */}
      </div>
      <div className="card__title__container">
        <div className="card__title">{title}</div>
        {/* <br /> */}
        <span className="card__price">${price}</span>
      </div>

      <div className="btn-container">
        {count !== 0 ? (
          <Button title={"-"} type={"subtract"} onClick={handleDecrement} />
        ) : (
          ""
        )}
        <Button
          title={`${count !== 0 ? "+" : "ADD"}`}
          type={"add"}
          onClick={handleIncrement}
        />
      </div>
    </div>
  );
}

export default Card;
