import {
  createNewOrderToServer,
  editOrderToServer,
  prepareOrderToServer,
  validateAvailableInventoryToServer,
} from "./HttpClient";

export const proceedOrderToServer = async (sessionToken, orderSummary) => {
  const response = await createNewOrderToServer(sessionToken, orderSummary);

  return true;
};
export const proceedEditOrderToServer = async (sessionToken, orderSummary) => {
  const response = await editOrderToServer(sessionToken, orderSummary);

  return true;
};
export const prepareOrder = async (
  sessionToken,
  cartItems,
  charges,
  routeCode,
  orderProfile
) => {
  const data = await prepareOrderToServer(
    sessionToken,
    cartItems,
    charges,
    routeCode,
    orderProfile
  );

  if (!data || Object.keys(data).length === 0) {
    console.log("No record for this man.");
    return [];
  }

  let chargeList = [];

  if (data.chargeList && data.chargeList.length > 0) {
    chargeList = data.chargeList.map((charge) => ({
      id: charge.id,
      chargetype: charge.chargetype,
      chargeamount: charge.chargeamount,
      chargedescription: charge.description,
      requesttitle: charge.RequestTitle,
    }));
  }

  const referenceRoute = data.referenceRoute[0];

  const settings = data.settings;

  return {
    chargeList,
    referenceRoute,
    settings,
  };
};

export const validateAvailableInventory = async (
  sessionToken,
  cartItems,
  existingOrderCartItems
) => {
  const data = await validateAvailableInventoryToServer(
    sessionToken,
    cartItems,
    existingOrderCartItems
  );

  if (!data) {
    // statusText === 'Created'
    //
    throw new Error("No inventory found.");
  }

  const updatedCartItems = data
    .map((item) => {
      const updatedQuantity = Math.min(
        parseInt(item.availableQty),
        parseInt(item.quantity)
      );

      return {
        ...item,
        quantity: updatedQuantity,
      };
    })
    .filter((item) => item.quantity > 0);

  const unavailableItems = data.filter(
    (item) => parseInt(item.quantity) > parseInt(item.availableQty)
  );

  const isAvailable = unavailableItems.length === 0;

  const itemsToRemove = unavailableItems.map((item) => {
    const updatedQuantity =
      parseInt(item.quantity) - parseInt(item.availableQty);
    return {
      ...item,
      quantity: updatedQuantity,
    };
  });

  return {
    isAvailable,
    updatedCartItems,
    itemsToRemove,
  };
};
