// ModalContext.js
import React, { createContext, useContext, useState } from "react";
import Modal from "../kit/Modal/Modal";

export const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    key: 0,
    title: "",
    description: "",
    inputValue: "",
    buttonLabel: "",
    onChange: null,
  });

  const [forceUpdateKey, setForceUpdateKey] = useState(0);

  const triggerModal = (
    key,
    title,
    description,
    inputValue,
    buttonLabel,
    onChange,
    onClick
  ) => {
    // Step 2: Increment the force update key to trigger a re-render
    setForceUpdateKey((prevKey) => prevKey + 1);

    setModalProps({
      isOpen: true,
      key,
      title,
      description,
      inputValue,
      buttonLabel,
      onChange,
      onClick,
    });
  };

  const closeModal = () => {
    setModalProps({
      isOpen: false,
      key: 0,
      title: "",
      description: "",
      inputValue: "",
      buttonLabel: "",
      onChange: null, // Reset onChange to null
      onClick: null,
    });
  };

  return (
    <ModalContext.Provider value={{ triggerModal, closeModal }}>
      {children}
      {modalProps.isOpen && (
        <Modal
          key={forceUpdateKey}
          {...modalProps}
          // title={modalProps.title}
          // description={modalProps.description}
          // inputValue={modalProps.inputValue}
          // buttonLabel={modalProps.buttonLabel}
          // onChange={modalProps.onChange}
          setTrigger={closeModal}
        />
      )}
    </ModalContext.Provider>
  );
};
