import React, { useCallback, useEffect } from "react";
import { useTelegram } from "../../../hooks/useTelegram";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import SendFormMessageToServerCall from "../../../components/app/server/SendFormMessageToServerCall";
import { useNavigate } from "react-router-dom";

import { PATH_CHECKOUT, PATH_ORDER_REVIEW } from "../../../constants/Paths";

const PaymentScreen = () => {
  const { webApp } = useTelegram();
  const navigate = useNavigate();

  const onBackClick = useCallback(() => {
    navigate(PATH_CHECKOUT, {
      replace: true,
    });
  }, [navigate]);

  useEffect(() => {
    webApp.ready();
    webApp.BackButton.show();
    webApp.BackButton.onClick(onBackClick);

    return () => {
      webApp.BackButton.offClick(onBackClick);
    };
  }, [webApp, onBackClick]);

  return (
    <TelegramScreen className="full-height">
      <SendFormMessageToServerCall pageTitle="NEW ORDER" />
    </TelegramScreen>
  );
};

export default PaymentScreen;
