export const PATH_HOME = "/";
export const PATH_CHECKOUT = "/store/new/checkout";
export const PATH_PAYMENT = "/store/new/payment";
export const PATH_PAYMENT_INFO = "/store/new/paymentinfo";
export const PATH_PRODUCT = "/store/selection/product";
export const PATH_CATEGORY = "/store/selection/productcategory";
export const PATH_BRAND = "/store/selection/productbrand";
export const PATH_PRODUCT_TYPE = "/store/selection/producttype";
export const PATH_THANKYOU = "/store/thankyou";
export const PATH_ORDER_MANAGEMENT = "/store/edit/orders";
export const PATH_ORDER_AMENDMENT = "/store/edit/payment";
export const PATH_ORDER_REVIEW = "/store/edit/checkout";
