import React from "react";
import "./OrderButton.css";
import { FaArrowRight, FaJediOrder } from "react-icons/fa6";
import { FaFirstOrder } from "react-icons/fa";
import TelegramText from "../Text/TelegramText";

function OrderButton({ title, className, handleButtonClick, type }) {
  return (
    <div className="ordercard">
      <div className="btn-container">
        <button
          className={`telegramButton order__card__button ${className}`}
          onClick={handleButtonClick}
        >
          <div className="order__content">
            <div className="image__container">
              {type === "edit" ? (
                <FaJediOrder />
              ) : type === "create" ? (
                <FaFirstOrder />
              ) : (
                <FaFirstOrder />
              )}
              {/* <img src={image} alt={title} /> */}
            </div>
            <TelegramText className="order__title">{title}</TelegramText>
            <div className="order__icon">
              <FaArrowRight />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default OrderButton;
