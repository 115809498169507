import {
  PATH_CHECKOUT,
  PATH_ORDER_AMENDMENT,
  PATH_PAYMENT,
} from "../../constants/Paths";
import {
  prepareOrder,
  validateAvailableInventory,
} from "../../logic/server/OrderFunction";
import {
  clearRequiredDeliveryCharges,
  clearRequiredExclusivePromotionCharges,
  clearRequiredSurchargeCharges,
  replaceCart,
  updateAgentPolicyManualPricing,
  updateBaseManualPricing,
  updateRequiredDeliveryCharges,
  updateRequiredExclusivePromotionCharges,
  updateRequiredSurchargeCharges,
  updateRouteDetails,
  updateSelectedTimeSlot,
  updateUserProfileDetails,
} from "../kit/Redux/Actions";

export const reviewOrder = async (
  utils,
  user,
  cartItems,
  existingOrderCartItems
) => {
  const { webApp, queryId, sessionToken, dispatch, navigate } = utils;
  try {
    // Enabling/disabling the button
    webApp.BackButton.hide();
    webApp.MainButton.disable();
    webApp.MainButton.showProgress(false);

    if (queryId) {
      const { isAvailable, updatedCartItems } =
        await validateAvailableInventory(
          sessionToken,
          cartItems,
          existingOrderCartItems
        );

      if (!isAvailable) {
        alert(
          "Some items in your cart are unavailable.\n\nPlease check and confirm to proceed anyways."
        );

        dispatch(replaceCart(updatedCartItems));

        navigate(PATH_CHECKOUT, {
          replace: true,
        });

        webApp.MainButton.hideProgress(true);
      } else {
        completeReviewOrder(utils, user, cartItems);
      }
    }
  } catch (error) {
    webApp.MainButton.hideProgress(true);
    webApp.MainButton.enable();
    webApp.BackButton.show();

    console.error("Error sending message:", error);
    // Handle the error gracefully, e.g., show an error message to the user
    alert(`${error}`);
  }
};

const completeReviewOrder = async (utils, user, cartItems) => {
  const { webApp, sessionToken, dispatch, navigate } = utils;
  try {
    const {
      userId,
      agentId,
      customerName,
      customerContact,
      customerAddress,
      timeSlot,
      surchargeChargeList,
      deliveryChargeList,
      exclusivePromotionChargeList,
    } = user;
    // Extract the first two digits from the address variable
    const routeCode = customerAddress.substr(0, 2);

    if (!timeSlot) {
      throw new Error("Invalid timeslot.");
    }

    const userDetails = {
      userId: userId,
      agentId: agentId,
    };

    const userProfile = {
      contactname: customerName,
      contactphone: customerContact,
      postalcode: customerAddress,
    };

    const orderProfile = {
      userProfile: userProfile,
      userDetails: userDetails,
      timeSlot: timeSlot,
    };

    // Prior to getting the surcharges, let's just clear it first
    dispatch(clearRequiredDeliveryCharges());
    dispatch(clearRequiredSurchargeCharges());
    // dispatch(clearRequiredConcurrentPromotionCharges());
    dispatch(clearRequiredExclusivePromotionCharges());

    const charges = [];

    if (surchargeChargeList && surchargeChargeList.length > 0) {
      // Let's check for Postal Code Surcharges
      const postalCodeChargeList = surchargeChargeList.filter(
        (item) => item.qualifier.toLowerCase() === "customeraddress"
      );

      if (postalCodeChargeList && postalCodeChargeList.length > 0) {
        const selectedPostalCodeCharge = postalCodeChargeList.find(
          (item) => item.qualifyingvalue === routeCode
        );

        if (selectedPostalCodeCharge) {
          const chargeId = selectedPostalCodeCharge.id;
          const chargeDetailCode = customerAddress;
          const requestCode = "getChargeByCode";
          const requestTitle = process.env.REACT_APP_SURCHARGE_CHARGES;
          const payload = {
            RequestTitle: requestTitle,
            RequestCode: requestCode,
            ChargeId: chargeId,
            ChargeDetailCode: chargeDetailCode,
          };

          if (charges) {
            charges.push(payload);
          }
        }
      }
    }

    if (deliveryChargeList && deliveryChargeList.length > 0) {
      // Let's check for Delivery Charges
      // Let's get the 'Standard' Delivery for now..
      const deliveryType = "Standard";

      const selectedDeliveryCharge = deliveryChargeList.find(
        (item) => item.qualifier.toLowerCase() === "delivery"
      );

      if (selectedDeliveryCharge) {
        const chargeId = selectedDeliveryCharge.id;
        const chargeDetailCode = deliveryType;
        const requestCode = "getChargeByCode";
        const requestTitle = process.env.REACT_APP_DELIVERY_CHARGES;

        const payload = {
          RequestTitle: requestTitle,
          RequestCode: requestCode,
          ChargeId: chargeId,
          ChargeDetailCode: chargeDetailCode,
        };

        if (charges) {
          charges.push(payload);
        }
      }
    }

    if (
      exclusivePromotionChargeList &&
      exclusivePromotionChargeList.length > 0
    ) {
      // Let's check the first type of  promotion
      // More than No Of Items
      const totalQtyInCart = cartItems.reduce(
        (total, item) => total + item.quantity,
        0
      );

      const selectedCharge = exclusivePromotionChargeList.find(
        (item) => item.qualifier.toLowerCase() === "noofitems"
      );

      if (selectedCharge) {
        const chargeId = selectedCharge.id;
        const operator = selectedCharge.qualifyingvalue;
        const chargeDetailCode = totalQtyInCart;
        const requestCode = "getChargesByCodeNOperator";
        const requestTitle = process.env.REACT_APP_EXCLUSIVE_PROMOTION;

        const payload = {
          RequestTitle: requestTitle,
          RequestCode: requestCode,
          ChargeId: chargeId,
          ChargeDetailCode: chargeDetailCode,
          ChargeDetailOperator: operator,
        };

        if (charges) {
          charges.push(payload);
        }
      }
    }

    const orderPreparation = await prepareOrder(
      sessionToken,
      cartItems,
      charges,
      routeCode,
      orderProfile
    );

    const { chargeList, referenceRoute, settings } = orderPreparation;

    if (!referenceRoute) {
      throw new Error("No routes were found based on your postal code.");
    }

    const routeId = referenceRoute.routeid;

    if (!routeId) {
      throw new Error("No routes were found based on your postal code.");
    }

    dispatch(updateRouteDetails(referenceRoute));

    if (chargeList && chargeList.length > 0) {
      const exclusivePromotions = chargeList.filter(
        (item) =>
          item.requesttitle === process.env.REACT_APP_EXCLUSIVE_PROMOTION
      );
      const deliveryCharges = chargeList.filter(
        (item) => item.requesttitle === process.env.REACT_APP_DELIVERY_CHARGES
      );
      const surchargeCharges = chargeList.filter(
        (item) => item.requesttitle === process.env.REACT_APP_SURCHARGE_CHARGES
      );

      if (exclusivePromotions) {
        dispatch(updateRequiredExclusivePromotionCharges(exclusivePromotions));
      }

      if (deliveryCharges) {
        dispatch(updateRequiredDeliveryCharges(deliveryCharges));
      }
      if (surchargeCharges) {
        dispatch(updateRequiredSurchargeCharges(surchargeCharges));
      }
    }

    dispatch(updateUserProfileDetails(userProfile));
    dispatch(updateSelectedTimeSlot(timeSlot));
    dispatch(updateBaseManualPricing(settings.enabledBaseManualPricing));
    dispatch(
      updateAgentPolicyManualPricing(settings.enabledAgentPolicyManualPricing)
    );

    navigate(PATH_ORDER_AMENDMENT, {
      replace: true,
    });

    webApp.MainButton.hideProgress(true);
  } catch (error) {
    console.error("Error sending message:", error);
    webApp.MainButton.hideProgress(true);
    webApp.MainButton.enable();
    webApp.BackButton.show();
    // Handle the error gracefully, e.g., show an error message to the user
    alert(`${error}`);
  }
};

export const areCartItemsSame = (newCart, existCart) => {
  if (newCart.length !== existCart.length) return false;

  const createItemMap = (items) =>
    items.reduce((acc, { id, quantity }) => {
      acc[id] = quantity;
      return acc;
    }, {});

  const map1 = createItemMap(newCart);
  const map2 = createItemMap(existCart);

  for (const [id, quantity] of Object.entries(map1)) {
    if (!map2[id] || map2[id] !== quantity) return false;
  }

  return true;
};

export const filterCartQuantityDifferences = (newCart, existCart) => {
  const createItemMap = (items) =>
    items.reduce((acc, { id, quantity }) => {
      acc[id] = quantity;
      return acc;
    }, {});

  const newCartMap = createItemMap(newCart);
  const existCartMap = createItemMap(existCart);

  const additionalItems = newCart.filter(({ id }) => !existCartMap[id]);
  const removedOrReducedItems = existCart.reduce((acc, { id, quantity }) => {
    if (!newCartMap[id]) {
      // Completely removed items
      acc.push({ id, quantity: 0 }); // Quantity set to 0 to indicate removal
    } else if (newCartMap[id] < quantity) {
      // Reduced quantity items
      acc.push({ id, quantity: newCartMap[id] - quantity }); // Negative value indicates reduction
    }
    return acc;
  }, []);

  return { additionalItems, removedOrReducedItems };
};

export const filterCartDifferences = (newCart, existCart) => {
  const createItemMap = (items) =>
    items.reduce((acc, item) => {
      acc[item.id] = item; // Store the whole item object instead of just the quantity
      return acc;
    }, {});

  const newCartMap = createItemMap(newCart);
  const existCartMap = createItemMap(existCart);

  // const additionalItems = newCart.filter(({ id }) => !existCartMap[id]);
  const removedOrReducedItems = existCart.reduce((acc, item) => {
    const { id, quantity } = item;
    if (!newCartMap[id]) {
      // Completely removed items
      acc.push({ ...item, quantity: 0 - existCartMap[id].quantity }); // Include whole item details, set quantity to 0 to indicate removal
    } else if (newCartMap[id].quantity < quantity) {
      // Reduced quantity items
      // Include whole item details, adjust quantity to show the reduction
      acc.push({ ...item, quantity: newCartMap[id].quantity - quantity });
    }
    return acc;
  }, []);

  const addedOrIncreasedItems = newCart.reduce((acc, item) => {
    const { id, quantity } = item;
    if (existCartMap[id] && quantity > existCartMap[id].quantity) {
      // Increased quantity items
      acc.push({ ...item, quantity: quantity - existCartMap[id].quantity }); // Adjust quantity to show the increase
    } else if (!existCartMap[id]) {
      // Newly added items
      acc.push({ ...item }); // Include whole item details for newly added items
    }
    return acc;
  }, []);

  return { addedOrIncreasedItems, removedOrReducedItems };
};
