import axios from "../api/axios.js";
import sjcl from "sjcl";
/*
HISTORY CHANGE
01/Apr/2024 VE  - Included axios default withCredentials = true;
*/

// axios.defaults.withCredentials = true; // Ensure cookies are sent with every request

const sjclSecret = process.env.REACT_APP_SJCL_SECRET;

// axios.defaults.withCredentials = true;

export async function getBrandByType(token, productType) {
  const endpoint = process.env.REACT_APP_API_BRAND_BY_TYPE;
  const payload = {
    productType: productType,
  };

  try {
    const data = await postToAPI(endpoint, payload, token);
    if (data || data.length > 0) {
      return data.map((brand) => ({
        id: brand.id,
        title: brand.item,
        descr: brand.description,
      }));
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export async function getCategoryByBrand(token, productType, brand) {
  const endpoint = process.env.REACT_APP_API_CATEGORY_BY_BRAND;
  const payload = {
    brand: brand,
    productType: productType,
  };

  try {
    const data = await postToAPI(endpoint, payload, token);
    if (data || data.length > 0) {
      return data.map((category) => ({
        id: category.id,
        title: category.item,
        descr: category.description,
        image: null,
      }));
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export async function getProductByCategory(
  token,
  policyId,
  category,
  brand,
  productType
) {
  const endpoint = process.env.REACT_APP_API_PRODUCT_BY_CATEGORY;
  const payload = {
    policyId: policyId,
    category: category,
    brand: brand,
    productType: productType,
  };

  try {
    const data = await postToAPI(endpoint, payload, token);
    if (data || data.length > 0) {
      return data.map((product) => ({
        id: product.id,
        title: product.name,
        price: parseFloat(product.price) || 999,
        category: category,
        brand: brand,
        type: productType,
        Image: null,
        availableQty: parseInt(product.availableqty) || 0,
      }));
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
}

export async function createNewOrder(token, payload) {
  const endpoint = process.env.REACT_APP_API_ORDER_CREATION;

  try {
    const data = await postToAPI(endpoint, payload, token);

    if (data || data.length > 0) {
      return data;
    } else {
      return;
    }
  } catch (error) {
    throw error;
  }
}
export async function editOrder(token, payload) {
  const endpoint = process.env.REACT_APP_API_ORDER_AMENDMENT;

  try {
    const data = await postToAPI(endpoint, payload, token);

    if (data || data.length > 0) {
      return data;
    } else {
      return;
    }
  } catch (error) {
    throw error;
  }
}

export async function broadcastMessage(token, payload) {
  const endpoint = "broadcastMessage";

  try {
    const data = await postToAPI(endpoint, payload, token);

    if (data || data.length > 0) {
      return data;
    } else {
      return;
    }
  } catch (error) {
    throw error;
  }
}

// WIP: Use sessions to control isAuthenticated
export async function authenticateUser(userId) {
  const endpoint = process.env.REACT_APP_API_AUTH;
  const payload = {
    UserId: userId,
  };

  try {
    const data = await postToAPI(endpoint, payload, "Manuals");
    if (data || data.length > 0) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function loginToServer(userId, agentKey) {
  const endpoint = process.env.REACT_APP_API_LOGIN;
  const payload = {
    UserId: userId,
    AgentKey: agentKey,
  };

  try {
    const data = await postToAPI(endpoint, payload, "Grills");
    if (data || data.length > 0) {
      return data;
    } else {
      return;
    }
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function getInitializationStatus(token, agentId, userId) {
  const endpoint = process.env.REACT_APP_API_INIT;
  const payload = {
    AgentId: agentId,
    UserId: userId,
  };

  try {
    const data = await postToAPI(endpoint, payload, token);

    if (!data || data.length === 0) {
      return [];
    }

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function prepareOrder(token, payload) {
  const endpoint = process.env.REACT_APP_API_ORDER_PREPARATION;

  try {
    const data = await postToAPI(endpoint, payload, token);

    if (data || data.length > 0) {
      return data;
    } else {
      return;
    }
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
}

export async function retrieveOrder(token, payload) {
  const endpoint = process.env.REACT_APP_API_ORDER_RETRIEVAL;

  try {
    const data = await postToAPI(endpoint, payload, token);

    if (!data || data.length === 0) {
      return [];
    }

    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function validateAvailableInventory(token, payload) {
  const endpoint = process.env.REACT_APP_API_ORDER_VALIDATION;

  try {
    const data = await postToAPI(endpoint, payload, token);

    if (data || data.length > 0) {
      return data;
    } else {
      return;
    }
  } catch (error) {
    throw error;
  }
}

export async function postToAPI(endpoint, payload, token) {
  const encryptedPayload = sjcl.encrypt(sjclSecret, JSON.stringify(payload));
  const encryptedToken = sjcl.encrypt(sjclSecret, JSON.stringify(token));

  try {
    const response = await axios.post(
      `${endpoint}`,
      { payload: encryptedPayload },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${encryptedToken}`,
        },
      }
    );

    // if (response.status === "401" || response.status === "403") {
    //   throw new Error("Unauthorized. Please login again.");
    // }

    // if (response.status === "400") {
    //   throw new Error("Unauthorized. Please login again.");
    // }

    // Check if cookies are present in the response headers
    // const cookies = response.headers["set-cookie"];
    // if (cookies) {
    //   alert("Received cookies:", cookies);
    //   // You can access individual cookies in the 'cookies' array
    // } else {
    //   alert("No cookies received");
    // }

    // Get the encrypted data from the request body
    const { responseData } = response.data;

    // Decrypt the data
    if (!responseData || responseData.length === 0) {
      return {};
    }
    const decryptedData = sjcl.decrypt(sjclSecret, responseData);

    const parsedJSON = JSON.parse(decryptedData);

    return parsedJSON;
  } catch (error) {
    // console.error("Error:", error);
    // throw new Error("Failed to make POST request to the API.");
    throw new Error(error);
  }
}

export async function putToAPI(endpoint, payload, token) {
  const encryptedPayload = sjcl.encrypt(sjclSecret, JSON.stringify(payload));
  const encryptedToken = sjcl.encrypt(sjclSecret, JSON.stringify(token));

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}${endpoint}`,
      { payload: encryptedPayload },
      {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${encryptedToken}`,
        },
      }
    );

    if (response.status === "401" || response.status === "403") {
      throw new Error("Unauthorized");
    }
    // Get the encrypted data from the request body
    const { responseData } = response.data;

    // Decrypt the data
    const decryptedData = sjcl.decrypt(sjclSecret, responseData);

    const parsedJSON = JSON.parse(decryptedData);

    return parsedJSON;
  } catch (error) {
    throw new Error("Unauthorized. Please try again.");
  }
}
