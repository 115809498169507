import React from "react";
import "./Modal.css";
import TelegramButton from "../Button/TelegramButton";
import TelegramInput from "../Input/TelegramInput";
import Avocado from "../../../images/avocado.png";

function Modal(props) {
  return (
    <section
      onClick={() => props.setTrigger(false)}
      className="modal__container"
    >
      <div onClick={(e) => e.stopPropagation()} className="modal">
        <div className="modal__icon">
          <img src={Avocado} alt="Avocado" width="50px" height="50px" />
          <TelegramButton
            className="modal__close__btn"
            onClick={() => props.setTrigger(false)}
          >
            X
          </TelegramButton>
        </div>
        <div className="modal__content">
          <h3 className="modal__title">{props.title}</h3>
          <p className="modal__description">{props.description}</p>
        </div>
        <TelegramInput
          type="number"
          step="0.01"
          placeholder={props.title}
          value={props.inputValue}
          onChange={props.onChange}
        />
        <TelegramButton className="modal__close__btn" onClick={props.onClick}>
          Submit
        </TelegramButton>
      </div>
    </section>
  );
}

export default Modal;
