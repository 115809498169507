import { PATH_THANKYOU } from "../../constants/Paths";
import { proceedOrderToServer } from "../../logic/server/OrderFunction";
import { buildOrderPayloadV2 } from "../../logic/server/PayloadBuilder";

// 28/Jun/2024 VE - New Function
//

export const submitNewOrder = async (utils, user, order, cartItems) => {
  const { webApp, sessionToken, navigate } = utils;
  try {
    const {
      userId,
      userName,
      agentId,
      routeDetails,
      customerName,
      customerContact,
      customerAddress,
      timeSlot,
      customerComments,
    } = user;

    const {
      isDeliveryWaived,
      discountAmount,
      deliveryCost,
      surchargeCost,
      paymentCost,
      storeTotalCost,
      charges,
    } = order;

    const userDetails = {
      userId: userId,
      userName: userName,
    };

    const postalCodeDetails = {
      address: customerAddress,
      postalCodeDescr: routeDetails.postalcodedescr,
    };

    const featureFlags = {
      isDeliveryWaived,
    };

    const orderCosts = {
      discountAmount: discountAmount,
      deliveryCost: deliveryCost,
      surchargeCost: surchargeCost,
      baseCost: paymentCost,
      storeTotalCost: storeTotalCost,
      charges: charges,
    };

    const customerDetails = {
      orderName: customerName,
      orderPhone: customerContact,
      orderAddress: customerAddress,
      orderComments: customerComments,
    };

    const orderSummary = buildOrderPayloadV2(
      userDetails,
      customerDetails,
      postalCodeDetails,
      featureFlags,
      cartItems,
      timeSlot,
      orderCosts,
      routeDetails,
      agentId
    );

    const result = await proceedOrderToServer(sessionToken, orderSummary);

    if (result) {
      navigate(PATH_THANKYOU, {
        replace: true,
      });
    }
  } catch (error) {
    console.error("Error sending message:", error);
    webApp.MainButton.hideProgress(true);
    webApp.BackButton.show();
    // Handle the error gracefully, e.g., show an error message to the user
    alert(`${error}`);
  }
};
