import React, { useCallback, useEffect, useState } from "react";
import "../../../App.css";

// import TelegramHeader from "../../components/kit/Header/TelegramHeader";
import TelegramText from "../../../components/kit/Text/TelegramText";
import { useTelegram } from "../../../hooks/useTelegram";
import { useNavigate } from "react-router-dom";
import {
  PATH_CATEGORY,
  PATH_CHECKOUT,
  PATH_ORDER_REVIEW,
} from "../../../constants/Paths";
import TelegramScreen from "../../../components/kit/Screen/TelegramScreen";
import TelegramDetailedButton from "../../../components/kit/DetailedButton/TelegramDetailedButton";
import Card from "../../../components/kit/Card/Card";
// import Cart from "../../components/kit/Cart/Cart";
import TelegramHeader from "../../../components/kit/Header/TelegramHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  removeFromCart,
  replaceCart,
} from "../../../components/kit/Redux/Actions";
import Loader from "../../../components/kit/Loader/Loader";

const ProductScreen = () => {
  const { webApp } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false); // State to track loading state

  const selectedCategory = useSelector((state) => state.selectedCategory);
  const products = useSelector((state) => state.products);
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const existingOrder = useSelector((state) => state.existingOrder);

  const onMainClick = useCallback(() => {
    setIsLoading(true);

    // alert(`View Trolley Clicked: ${JSON.stringify(cartItems)}`);

    const sortedCartItems = [...cartItems].sort((a, b) => {
      // Sort by Type
      const typeResult = a.type.localeCompare(b.type);
      if (typeResult !== 0) return typeResult;

      // Sort by Brand
      const brandResult = a.brand.localeCompare(b.brand);
      if (brandResult !== 0) return brandResult;

      // Sort by Category
      const categoryResult = a.category.localeCompare(b.category);
      if (categoryResult !== 0) return categoryResult;

      // Sort by Title
      return a.title.localeCompare(b.title);
    });

    dispatch(replaceCart(sortedCartItems));

    // alert(`View Trolley Clicked: ${JSON.stringify(sortedCartItems)}`);

    if (existingOrder) {
      navigate(PATH_ORDER_REVIEW, { replace: true });
    } else {
      navigate(PATH_CHECKOUT, { replace: true });
    }
  }, [navigate, cartItems, dispatch]);

  const onBackClick = useCallback(() => {
    navigate(PATH_CATEGORY, { replace: true });
  }, [navigate]);

  useEffect(() => {
    webApp.ready();
    webApp.MainButton.onClick(onMainClick);
    webApp.BackButton.onClick(onBackClick);

    webApp.MainButton.text = "VIEW TROLLEY";
    // webApp.MainButton.color = "#1c54bd";
    webApp.MainButton.color = "#31b545";

    if (cartItems.length > 0) {
      webApp.MainButton.show();
    } else {
      webApp.MainButton.hide();
    }

    return () => {
      webApp.MainButton.offClick(onMainClick);
      webApp.BackButton.offClick(onBackClick);
    };
  }, [webApp, cartItems, onMainClick, onBackClick]);

  const onAdd = (item, newAddQty) => {
    dispatch(addToCart(item, newAddQty));
  };

  const onRemove = (item, newSubQty) => {
    dispatch(removeFromCart(item, newSubQty));
  };

  return (
    products && (
      <TelegramScreen showbackbutton={1}>
        {!isLoading ? (
          <div>
            <TelegramHeader>
              <TelegramText className={"telegramTitle"}>
                {selectedCategory}
              </TelegramText>
            </TelegramHeader>
            <div className="cards__container">
              {products.map((item) => {
                const itemInCart = Array.isArray(cartItems)
                  ? cartItems.find((cartItem) => cartItem.id === item.id)
                  : null;
                const currentCount = itemInCart ? itemInCart.quantity : 0;
                return (
                  <Card
                    food={item}
                    key={item.id}
                    currentCount={currentCount}
                    onAdd={onAdd}
                    onRemove={onRemove}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <Loader title="Rendering..." />
        )}
      </TelegramScreen>
    )
  );
};

export default ProductScreen;
