import React, { useState } from "react";
import "./CheckoutCard.css";
import CheckoutButton from "../CheckoutButton/CheckoutButton";

function CheckoutCard({
  food,
  quantity,
  availableQty,
  onAdd,
  onRemove,
  isDisabledButton,
}) {
  const [isShaking, setIsShaking] = useState(false);

  const { title, price, category, brand } = food;

  const totalPrice = price * quantity;

  const handleIncrement = (amount) => {
    if (quantity === availableQty) {
      // Set isShaking to true to trigger the animation
      setIsShaking(true);

      // After a short delay, reset isShaking to false to stop the animation
      setTimeout(() => {
        setIsShaking(false);
      }, 1000); // Adjust the duration of the animation as needed
    } else if (quantity < availableQty) {
      let newAdditionalQty = amount;
      if (quantity + amount > availableQty) {
        newAdditionalQty = availableQty - quantity;
      }
      onAdd(food, newAdditionalQty);
    }
  };

  // amount will be in negative
  const handleDecrement = (amount) => {
    let newSubtractQty = amount;
    if (quantity - amount <= 0) {
      // quantity + (- amount)
      newSubtractQty = quantity - 0;
    }
    onRemove(food, newSubtractQty);
  };

  return (
    <div className="checkout__card">
      <div className="checkout__image__container">🍼</div>

      <div className="checkout__card__title">
        <h4 className="checkout__title">
          {brand} {category} {title}{" "}
        </h4>
        <div className="checkout-btn-container">
          {quantity >= 5 ? (
            <CheckoutButton
              className="decrement-5"
              title="-5"
              type="subtract"
              onClick={() => handleDecrement(5)} // Adjust handleDecrement to accept a parameter for the amount to decrement
            />
          ) : (
            ""
          )}
          {quantity > 0 ? (
            <CheckoutButton
              className="decrement"
              title="-"
              type="subtract"
              onClick={() => handleDecrement(1)} // Adjust existing decrement to subtract by 1
            />
          ) : (
            ""
          )}
          {quantity}
          <CheckoutButton
            className={`${isShaking ? "shake2" : ""}`}
            title={`${quantity > 0 ? "+" : "ADD"}`}
            type="add"
            onClick={() => handleIncrement(1)} // Adjust existing increment to add by 1
          />
          {quantity >= 5 ? (
            <CheckoutButton
              className={`increment-5 ${isShaking ? "shake2" : ""}`}
              title="+5"
              type="add"
              onClick={() => handleIncrement(5)} // Adjust handleIncrement to accept a parameter for the amount to increment
            />
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="checkout__card__total">
        <span className="checkout__card__price">${totalPrice.toFixed(2)}</span>
      </div>
    </div>
  );
}

export default CheckoutCard;
