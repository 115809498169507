import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./Reducers.jsx";

// Configure the Redux store with the root reducer
const store = configureStore({
  reducer: rootReducer,
  // Add any other middleware or enhancers as needed
});

export default store;
