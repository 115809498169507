import { useNavigate } from "react-router-dom";
import TelegramMiniMultiInputForm from "../../kit/MiniMultiInputForm/TelegramMiniMultiInputForm";
import { useTelegram } from "../../../hooks/useTelegram";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "../../providers/AlertProvider";
import {
  PATH_CHECKOUT,
  PATH_ORDER_AMENDMENT,
  PATH_PAYMENT,
} from "../../../constants/Paths";
import TelegramScreen from "../../kit/Screen/TelegramScreen";
import {
  clearRequiredDeliveryCharges,
  clearRequiredExclusivePromotionCharges,
  clearRequiredSurchargeCharges,
  replaceCart,
  updateAgentPolicyManualPricing,
  updateBaseManualPricing,
  updateRequiredDeliveryCharges,
  updateRequiredExclusivePromotionCharges,
  updateRequiredSurchargeCharges,
  updateRouteDetails,
  updateSelectedTimeSlot,
  updateUserProfileDetails,
} from "../../kit/Redux/Actions";
import {
  prepareOrder,
  validateAvailableInventory,
} from "../../../logic/server/OrderFunction";

/**
 * /messages endpoint is used to send messages to the bot server using a REST API.
 * The bot will receive the message and echo it in the chat.
 *
 * @see ConfirmUserDetailsFormMessage
 */
const ConfirmUserDetailsFormMessage = () => {
  const { webApp, user } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { triggerAlert } = useAlert();

  const agent = useSelector((state) => state.agent);
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const sessionToken = useSelector((state) => state.sessionToken);
  const surchargeChargeList = useSelector((state) => state.surchargeChargeList);
  const deliveryChargeList = useSelector((state) => state.deliveryChargeList);
  const exclusivePromotionChargeList = useSelector(
    (state) => state.exclusivePromotionChargeList
  );
  // This id will be used by our backend to send messages to the chat with the user
  const queryId = webApp.initDataUnsafe?.query_id;

  const onMainClick = async ({ name, phone, address, timeSlot }) => {
    try {
      // Enabling/disabling the button
      webApp.BackButton.hide();
      webApp.MainButton.disable();
      webApp.MainButton.showProgress(false);

      if (queryId) {
        const { isAvailable, updatedCartItems } =
          await validateAvailableInventory(sessionToken, cartItems, null);

        if (!isAvailable) {
          alert(
            "Some items in your cart are unavailable.\n\nPlease check and confirm to proceed anyways."
          );

          dispatch(replaceCart(updatedCartItems));

          navigate(PATH_CHECKOUT, {
            replace: true,
          });

          webApp.MainButton.hideProgress(true);
        } else {
          proceedOrder(name, phone, address, timeSlot, cartItems);
        }
      }
    } catch (error) {
      webApp.MainButton.hideProgress(true);
      webApp.MainButton.enable();
      webApp.BackButton.show();

      console.error("Error sending message:", error);
      // Handle the error gracefully, e.g., show an error message to the user
      alert(`${error}`);
    }
  };

  const proceedOrder = async (name, phone, address, timeSlot, cart) => {
    try {
      // Extract the first two digits from the address variable
      const routeCode = address.substr(0, 2);

      if (!timeSlot) {
        throw new Error("Invalid timeslot.");
      }

      const userDetails = {
        userId: user?.id,
        agentId: agent.id,
      };

      const userProfile = {
        contactname: name,
        contactphone: phone,
        postalcode: address,
      };

      const orderProfile = {
        userProfile: userProfile,
        userDetails: userDetails,
        timeSlot: timeSlot,
      };

      // Prior to getting the surcharges, let's just clear it first
      dispatch(clearRequiredDeliveryCharges());
      dispatch(clearRequiredSurchargeCharges());
      // dispatch(clearRequiredConcurrentPromotionCharges());
      dispatch(clearRequiredExclusivePromotionCharges());

      const charges = [];

      if (surchargeChargeList && surchargeChargeList.length > 0) {
        // Let's check for Postal Code Surcharges
        const postalCodeChargeList = surchargeChargeList.filter(
          (item) => item.qualifier.toLowerCase() === "customeraddress"
        );

        if (postalCodeChargeList && postalCodeChargeList.length > 0) {
          const selectedPostalCodeCharge = postalCodeChargeList.find(
            (item) => item.qualifyingvalue === routeCode
          );

          if (selectedPostalCodeCharge) {
            const chargeId = selectedPostalCodeCharge.id;
            const chargeDetailCode = address;
            const requestCode = "getChargeByCode";
            const requestTitle = process.env.REACT_APP_SURCHARGE_CHARGES;
            const payload = {
              RequestTitle: requestTitle,
              RequestCode: requestCode,
              ChargeId: chargeId,
              ChargeDetailCode: chargeDetailCode,
            };

            if (charges) {
              charges.push(payload);
            }
          }
        }
      }

      if (deliveryChargeList && deliveryChargeList.length > 0) {
        // Let's check for Delivery Charges
        // Let's get the 'Standard' Delivery for now..
        const deliveryType = "Standard";

        const selectedDeliveryCharge = deliveryChargeList.find(
          (item) => item.qualifier.toLowerCase() === "delivery"
        );

        if (selectedDeliveryCharge) {
          const chargeId = selectedDeliveryCharge.id;
          const chargeDetailCode = deliveryType;
          const requestCode = "getChargeByCode";
          const requestTitle = process.env.REACT_APP_DELIVERY_CHARGES;

          const payload = {
            RequestTitle: requestTitle,
            RequestCode: requestCode,
            ChargeId: chargeId,
            ChargeDetailCode: chargeDetailCode,
          };

          if (charges) {
            charges.push(payload);
          }
        }
      }

      if (
        exclusivePromotionChargeList &&
        exclusivePromotionChargeList.length > 0
      ) {
        // Let's check the first type of  promotion
        // More than No Of Items
        const totalQtyInCart = cart.reduce(
          (total, item) => total + item.quantity,
          0
        );

        const selectedCharge = exclusivePromotionChargeList.find(
          (item) => item.qualifier.toLowerCase() === "noofitems"
        );

        if (selectedCharge) {
          const chargeId = selectedCharge.id;
          const operator = selectedCharge.qualifyingvalue;
          const chargeDetailCode = totalQtyInCart;
          const requestCode = "getChargesByCodeNOperator";
          const requestTitle = process.env.REACT_APP_EXCLUSIVE_PROMOTION;

          const payload = {
            RequestTitle: requestTitle,
            RequestCode: requestCode,
            ChargeId: chargeId,
            ChargeDetailCode: chargeDetailCode,
            ChargeDetailOperator: operator,
          };

          if (charges) {
            charges.push(payload);
          }
        }
      }

      const orderPreparation = await prepareOrder(
        sessionToken,
        cart,
        charges,
        routeCode,
        orderProfile
      );

      const { chargeList, referenceRoute, settings } = orderPreparation;

      if (!referenceRoute) {
        throw new Error("No routes were found based on your postal code.");
      }

      const routeId = referenceRoute.routeid;

      if (!routeId) {
        throw new Error("No routes were found based on your postal code.");
      }

      dispatch(updateRouteDetails(referenceRoute));

      if (chargeList && chargeList.length > 0) {
        const exclusivePromotions = chargeList.filter(
          (item) =>
            item.requesttitle === process.env.REACT_APP_EXCLUSIVE_PROMOTION
        );
        const deliveryCharges = chargeList.filter(
          (item) => item.requesttitle === process.env.REACT_APP_DELIVERY_CHARGES
        );
        const surchargeCharges = chargeList.filter(
          (item) =>
            item.requesttitle === process.env.REACT_APP_SURCHARGE_CHARGES
        );

        if (exclusivePromotions) {
          dispatch(
            updateRequiredExclusivePromotionCharges(exclusivePromotions)
          );
        }

        if (deliveryCharges) {
          dispatch(updateRequiredDeliveryCharges(deliveryCharges));
        }
        if (surchargeCharges) {
          dispatch(updateRequiredSurchargeCharges(surchargeCharges));
        }
      }

      dispatch(updateUserProfileDetails(userProfile));
      dispatch(updateSelectedTimeSlot(timeSlot));
      dispatch(updateBaseManualPricing(settings.enabledBaseManualPricing));
      dispatch(
        updateAgentPolicyManualPricing(settings.enabledAgentPolicyManualPricing)
      );

      if (settings.isExistingOrder) {
        triggerAlert(
          "Work In Progress",
          `Restart to amend order [${settings.isExistingOrder.orderno}].`
        );
      } else {
        navigate(PATH_PAYMENT, {
          replace: true,
        });
      }

      webApp.MainButton.hideProgress(true);
    } catch (error) {
      console.error("Error sending message:", error);
      webApp.MainButton.hideProgress(true);
      webApp.MainButton.enable();
      webApp.BackButton.show();
      // Handle the error gracefully, e.g., show an error message to the user
      alert(`${error}`);
    }
  };

  return (
    <TelegramScreen>
      <TelegramMiniMultiInputForm
        fieldlabel1={"Name"}
        fieldhint1={`Enter the name`}
        fieldlabel2={"Phone No."}
        fieldhint2={`Enter the phone number`}
        fieldlabel3={"Postal Code"}
        fieldhint3={`Enter the postal code`}
        fieldlabel4={"Timeslot"}
        onSubmit={onMainClick}
      />
    </TelegramScreen>
  );
};

export default ConfirmUserDetailsFormMessage;
